<template>
  <div>
    <div class="upload">
      <el-upload
        class="upload-demo"
        :action="getUploadUrl('blhh')"
        name="excelFile"
        :on-success="uploadResult"
        :on-error="falseResult"
        :on-progress="uploading"
      >
        <el-button size="small" type="primary">导入百丽货号Excel</el-button>
      </el-upload>

      <!-- <el-upload
        class="upload-demo"
        :action="getUploadUrl('blkc')"
        name="excelFile"
        :on-success="uploadResult"
      >
        <el-button size="small" type="primary">导入百丽库存Excel</el-button>
      </el-upload> -->

      <el-upload
        class="upload-demo"
        :action="getUploadUrl('A')"
        name="excelFile"
      >
        <el-button size="small" type="primary">A 导入普通现货Excel</el-button>
      </el-upload>

      <el-upload
        class="upload-demo"
        :action="getUploadUrl('B')"
        name="excelFile"
      >
        <el-button size="small" type="primary"
          >B 导入品牌专供现货Excel</el-button
        >
      </el-upload>

      <el-upload
        class="upload-demo"
        action="http://www.dewutest.com/api/import/data/from/excel?type=ppxhexcel"
        name="excelFile"
      >
        <el-button size="small" type="primary">C 自动更新入仓数据</el-button>
      </el-upload>

      <el-upload
        class="upload-demo"
        :action="getUploadUrl('D')"
        name="excelFile"
      >
        <el-button size="small" type="primary">D 导入现货订单Excel</el-button>
      </el-upload>

      <el-upload
        class="upload-demo"
        :action="getUploadUrl('E')"
        name="excelFile"
      >
        <el-button size="small" type="primary">E 导入入仓订单Excel</el-button>
      </el-upload>

      <!-- <el-button size="small" type="primary">导出表格：品牌专供看齐现货</el-button> -->
    </div>
    <div v-if="notMatchNumber != null">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>无法匹配的得物货号</span>
          <el-button
            style="float: right; padding: 3px 0"
            type="text"
            @click="closeTip"
            >关闭窗口</el-button
          >
        </div>
        <div v-for="o in notMatchNumber" :key="o" class="text item">
          {{ o }}
        </div>
      </el-card>
    </div>

    <div class="conditionsDiv">
      <el-badge :value="badgeNumber['xh_and_ppxh_noteq']" class="item">
        <el-button
          :type="conditions['xh_and_ppxh_noteq'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('xh_and_ppxh_noteq')"
          >现货与品牌专供价不一致</el-button
        >
      </el-badge>

      <el-badge :value="badgeNumber['xh_more']" class="item">
        <el-button
          :type="conditions['xh_more'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('xh_more')"
          >现货出价 ＞ 全渠道</el-button
        >
      </el-badge>

      <el-badge :value="badgeNumber['ppxh_more']" class="item">
        <el-button
          :type="conditions['ppxh_more'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('ppxh_more')"
          >品牌专供出价 ＞ 全渠道</el-button
        >
      </el-badge>

      <el-badge :value="badgeNumber['storage_price_more']" class="item">
        <el-button
          :type="conditions['storage_price_more'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('storage_price_more')"
          >入仓出价 ＞ 全渠道</el-button
        >
      </el-badge>

      <!-- <el-badge :value="badgeNumber['xh_and_ppxh_noteq']" class="item">
        <el-button
          :type="conditions['xh_and_ppxh_noteq'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('xh_and_ppxh_noteq')"
          >现货出价 ＞ 全渠道</el-button
        >
      </el-badge> -->
    </div>

    <div class="conditionsDiv">
      <el-badge :value="badgeNumber['xh_sell_out']" class="item">
        <el-button
          :type="conditions['xh_sell_out'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('xh_sell_out')"
          >现货售罄</el-button
        >
      </el-badge>

      <el-badge :value="badgeNumber['storage_sell_out']" class="item">
        <el-button
          :type="conditions['storage_sell_out'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('storage_sell_out')"
          >入仓售罄</el-button
        >
      </el-badge>

      <el-badge :value="badgeNumber['storage_bid_qty_eq0']" class="item">
        <el-button
          :type="conditions['storage_bid_qty_eq0'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('storage_bid_qty_eq0')"
          >入仓出价数为0，且有待出价</el-button
        >
      </el-badge>

      <el-badge :value="badgeNumber['xh_no_price']" class="item">
        <el-button
          :type="conditions['xh_no_price'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('xh_no_price')"
          >普通现货未出价</el-button
        >
      </el-badge>

      <el-badge :value="badgeNumber['ppxh_no_price']" class="item">
        <el-button
          :type="conditions['ppxh_no_price'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('ppxh_no_price')"
          >品牌专供未出价</el-button
        >
      </el-badge>

      <el-badge :value="badgeNumber['not_in_storage']" class="item">
        <el-button
          :type="conditions['not_in_storage'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('not_in_storage')"
          >未入仓</el-button
        >
      </el-badge>

      <!-- <el-badge :value="badgeNumber['storage_price_more']" class="item">
        <el-button
          :type="conditions['storage_price_more'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('storage_price_more')"
          >得物在售但百丽表格未收录</el-button
        >
      </el-badge> -->
    </div>

    <div>
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="loading"
      >
        <el-table-column prop="dwgoods_spu_logo" label="图片" align="center">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                :src="scope.row.dwgoods_spu_logo"
                :preview-src-list="[scope.row.dwgoods_spu_logo]"
              >
              </el-image>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="dwgoods_title" label="商品名称" align="center">
        </el-table-column>

        <el-table-column label="货号" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.bl_is_bingkuan == 1">
              <!-- <div>并款</div> -->
              <div>得物货号</div>
              <div>{{ scope.row.dwgoods_article_number }}</div>
              <div>百丽货号</div>
              <div>{{ scope.row.bl_article_number }}</div>
            </span>
            <span v-else>
              {{ scope.row.dwgoods_article_number }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          prop="dwgoods_skus_properties"
          label="规格"
          align="center"
        >
          <template slot-scope="scope">
            <pre>{{ getProps(scope.row.dwgoods_skus_properties) }}</pre>
          </template>
        </el-table-column>

        <!-- <el-table-column
          prop="bl_storage_number"
          label="百丽库存"
          align="center"
        >
        </el-table-column> -->

        <el-table-column prop="bl_price" label="官方价" align="center">
          <template slot-scope="scope">
            <pre>{{ scope.row.bl_price }}<br>{{ Math.floor(scope.row.bl_price*0.6/10)*10+9 }}(6折)</pre>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="bl_status" label="百丽状态" align="center">
        </el-table-column> -->

        <!-- <el-table-column prop="bl_remark" label="备注" align="center">
        </el-table-column> -->

        <el-table-column prop="lower_price" label="全渠道最低价" align="center">
          <template slot-scope="scope">
            {{ scope.row.lower_price }}
            <div v-if="scope.row.lower_price">
              ({{
                ((scope.row.lower_price / scope.row.bl_price) * 10).toFixed(1)
              }}折)
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="xhdata_my_price" label="我现货价" align="center">
          <template slot-scope="scope">
            {{ scope.row.xhdata_my_price }}
            <div v-if="scope.row.xhdata_my_price">
              ({{
                ((scope.row.xhdata_my_price / scope.row.bl_price) * 10).toFixed(
                  1
                )
              }}折)
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="ppxhdata_my_price"
          label="我品牌专供价"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.ppxhdata_my_price }}
            <div v-if="scope.row.ppxhdata_my_price">
              ({{
                (
                  (scope.row.ppxhdata_my_price / scope.row.bl_price) *
                  10
                ).toFixed(1)
              }}折)
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="storage_my_min_price"
          label="我入仓价"
          align="center"
        >
          <template slot-scope="scope">
            <!-- {{ scope.row.storage_my_min_price }} -->
            <div
              v-if="
                scope.row.storage_my_min_price || scope.row.storage_not_bid_qty
              "
            >
              {{ scope.row.storage_my_min_price }}({{
                (
                  (scope.row.storage_my_min_price / scope.row.bl_price) *
                  10
                ).toFixed(1)
              }}折)
              <br />
              出价数: {{ scope.row.storage_bid_qty }}
              <br />
              待出价数: {{ scope.row.storage_not_bid_qty }}
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column
          prop="storage_current_channel_min_price"
          label="入仓最低价"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.storage_current_channel_min_price }}
            <div v-if="scope.row.storage_current_channel_min_price">
              ({{
                (
                  (scope.row.storage_current_channel_min_price /
                    scope.row.bl_price) *
                  10
                ).toFixed(1)
              }}折)
            </div>
          </template>
        </el-table-column> -->

        <el-table-column label="操作" align="center">
          <el-button size="mini" type="default">隐藏</el-button>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="data_total"
        :page-size="data_PageSize"
        :current-page="data_CurrentPage"
        @current-change="changePage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      notMatchNumber: null,
      page: 1,
      data_total: 0,
      data_PageSize: 0,
      data_CurrentPage: 0,
      tableData: [],
      conditions: {
        'xh_and_ppxh_noteq': 0,
        'xh_more': 0,
        'ppxh_more': 0,
        'storage_price_more': 0,
        'storage_bid_qty_eq0': 0,
        'not_in_storage': 0,
        'xh_no_price': 0,
        'ppxh_no_price': 0,
        'xh_sell_out': 0,
        'storage_sell_out': 0,
        // 'more_than_system_shop': 0,
        // 'more_than_system_storage': 0,
        // 'bid_qty_qe_0': 0,
        // 'bid_qty_less_5': 0,
        // 'show_hidden': 0,
        // 'show_attention': 0,
        // 'show_all': 1,
      },
      badgeNumber: {
        'xh_and_ppxh_noteq': null,
        'xh_more': null,
        'ppxh_more': null,
        'storage_price_more': null,
        'storage_bid_qty_eq0': null,
        'not_in_storage': null,
        'xh_no_price': null,
        'ppxh_no_price': null,
        'xh_sell_out': null,
        'storage_sell_out': null,
        // 'more_than_system_shop': null,
        // 'more_than_system_storage': null,
        // 'bid_qty_qe_0': null,
        // 'bid_qty_less_5': null,
        // 'show_hidden': 0,
        // 'show_attention': 0,
        // 'show_all': 0,
      }
    };
  },
  created () {
    // console.log(this.$api.getDomian());
    this.init();
  },
  methods: {
    init () {
      if (!this.loading) {
        this.loading = true;
        this.$api.post(
          "get/mygoods/list",
          { page: this.page, conditions: this.conditions },
          (r) => {
            this.loading = false;
            if (r.errcode != 0) {
              return this.$message.error(r.errmsg);
            }
            // console.log(r.data);
            this.tableData = r.data.items;
            this.data_total = r.data.total;
            this.data_PageSize = r.data.perPage;
            this.data_CurrentPage = r.data.currentPage;
            if (r.data.badgeNumber != null) {
              this.badgeNumber = r.data.badgeNumber
            }
          }
        );
      }
    },
    uploadResult (res) {
      this.loading = false;
      if (res.data != null) {
        this.notMatchNumber = res.data.notMatch;
        this.page = 1;
        this.init();
      } else {
        this.$message.error('上传失败');
      }
    },
    closeTip () {
      this.notMatchNumber = null;
    },
    getProps (props) {
      var json = eval("(" + props + ")");
      let str = "";
      for (var val in json) {
        str += val + ":" + json[val] + "\n";
      }
      return str;
    },
    changePage (v) {
      this.page = v;
      this.init();
    },
    setCondition (key) {
      for (let key in this.conditions) {
        this.conditions[key] = 0
      }
      this.conditions[key] = 1
      this.page = 1;
      this.init();
    },
    getUploadUrl (type) {
      let url = "";
      switch (type) {
        case 'blhh':
          url = this.$api.getDomian() + '/import/bl/data/from/excel'
          break;
        case 'blkc':
          url = this.$api.getDomian() + '/import/bl/data/from/excel'
          break;
        case 'A':
          url = this.$api.getDomian() + '/import/data/from/excel?type=xhexcel'
          break;
        case 'B':
          url = this.$api.getDomian() + '/import/data/from/excel?type=ppxhexcel'
          break;
        case 'D':
          url = this.$api.getDomian() + '/import/order/data/from/excel?type=xhorder'
          break;
        case 'E':
          url = this.$api.getDomian() + '/import/order/data/from/excel?type=storageorder'
          break;
        default:
          break;
      }
      return url;
    },
    uploading () {
      this.loading = true
    },
    falseResult () {
      this.loading = false
      this.$message.error('上传失败');
    }
  },
};
</script>

<style scoped>
.upload {
  display: flex;
}
.upload-demo {
  margin-left: 20px;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 480px;
}
.demo-image__preview {
  width: 50%;
  margin: auto;
}
.conditionsDiv {
  margin-top: 20px;
}
</style>