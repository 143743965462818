<template>
  <el-container>
    <el-aside style="width: auto">
      <el-menu default-active="1-4-1" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse" :defaultActive="activeIndex">
        <el-menu-item @click="changeCollapse">
          <i class="el-icon-full-screen"></i>
          <span slot="title">侧栏展开/收起</span>
        </el-menu-item>
        <el-menu-item index="1" @click="goto('index')" v-show="finish">
          <i class="el-icon-s-home"></i>
          <span slot="title">首页</span>
        </el-menu-item>
        <el-submenu index="2" v-show="finish">
          <template slot="title">
            <i class="el-icon-money"></i>
            <span slot="title">出价盯盘</span>
          </template>
          <el-menu-item index="2-3" @click="goto('handle_price_excel')">Excel盯盘</el-menu-item>
          <el-menu-item index="2-1" @click="goto('storage_make_price')" v-show="finish">入仓出价</el-menu-item>
          <el-menu-item index="2-2" @click="goto('shop_make_price')">现货出价</el-menu-item>
        </el-submenu>
        <el-menu-item index="3" @click="goto('index_stock')" v-show="finish">
          <i class="el-icon-truck"></i>
          <span slot="title">库存管理</span>
        </el-menu-item>
        <el-submenu index="4">
          <template slot="title">
            <i class="el-icon-data-analysis"></i>
            <span slot="title">数据分析</span>
          </template>
          <!-- <el-menu-item index="4-1" @click="goto('data_statistics')">单品销售统计</el-menu-item> -->
          <!-- <el-menu-item index="4-2" @click="goto('data_statistics_period')">周期销售统计</el-menu-item> -->
          <el-menu-item index="4-4" @click="goto('sale_daily')">运营数据</el-menu-item>
          <el-menu-item index="4-5" @click="goto('data_product')">单品分析</el-menu-item>
          <el-menu-item index="4-6" @click="goto('trend_statistics')">趋势统计</el-menu-item>
          <!-- <el-menu-item index="4-3" @click="goto('xuexi')">学习</el-menu-item> -->
          <!-- <el-menu-item index="4-2">转化率</el-menu-item> -->
          <!-- <el-menu-item-group title="日数据">
            <el-menu-item index="1-1">日报</el-menu-item>
            <el-menu-item index="1-2">选项2</el-menu-item>
          </el-menu-item-group> -->
        </el-submenu>
        <el-submenu index="5">
          <template slot="title">
            <i class="el-icon-coin"></i>
            <span slot="title">数据中心</span>
          </template>
          <el-menu-item index="5-1" @click="goto('dewu_data')">得物数据</el-menu-item>
          <el-menu-item index="5-2" @click="goto('dewu_data_export')">导出数据</el-menu-item>
          <el-menu-item index="5-3" @click="goto('dewu_data_import')">导入数据</el-menu-item>
        </el-submenu>
        <!-- <el-menu-item index="6" @click="authorize">
          <i class="el-icon-thumb"></i>
          <span slot="title">授权中心</span>
        </el-menu-item> -->
        <el-menu-item @click="loginOut">
          <i class="el-icon-circle-close"></i>
          <span slot="title">退出登陆</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-container>
      <el-main :class="String($store.state.menu_isCollapse) === 'false' ? 'el-main-l' : 'el-main-s'">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<style>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}
/* 
.el-aside {
  color: #333;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}

.el-main {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  overflow-y: scroll;
}

.el-main-s {
  left: 60px;
}

.el-main-l {
  left: 200px;
} */

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  /* min-height: 400px; */
}
</style>

<script>
export default {
  data () {
    return {
      finish: false,
      activeIndex: this.$route.meta.menu_index,
    };
  },
  computed: {
    isCollapse: function () {
      return String(this.$store.state.menu_isCollapse) === "false"
        ? false
        : true;
    },
  },
  mounted () {
    // console.log(this.$route.name);
    // this.$message.warning("aaaa");
  },
  created () {
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      // console.log('手机端')
      this.$store.commit("setPhoneMenuCollapse");
    }
  },
  methods: {
    handleOpen (key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath);
    },
    changeCollapse () {
      this.$store.commit("setMenuCollapse");
      // this.isCollapse = !this.isCollapse;
    },
    goto (v) {
      if (this.$route.name != v) {
        this.$router.push({ name: v });
      }
    },
    loginOut () {
      localStorage.setItem("dewu_user_token", null);
      this.$router.push({ name: "login" });
    },
    authorize () {
      this.$router.push({ name: "authorize" });
      // window.open('https://open.dewu.com/#/authorize?response_type=code&redirect_uri='+encodeURIComponent ('http://dw.sikers.cn/api/receive-push')+'&client_id=4486fdc3a64742a79f3de4570cae1b68&scope=all');
    },
    
  },
};
</script>