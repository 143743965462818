<template>
  <div>
    <div>
      <span>店铺：{{ shop_name }}</span>
      <span class="title">品牌：{{ brand_name }} </span>
    </div>
    <el-date-picker style="margin-top: 20px" class="search-item" v-model="createTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" :default-time="createTime"></el-date-picker>
    <el-select class="search-item" multiple v-model="brandValue" clearable placeholder="请选择品牌"><el-option v-for="item in brandOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select>
    <div>
      <span>商品信息</span>
      <span class="title"></span>
    </div>
    <div class="funcitons">
      <el-row>
        <el-button type="primary" :loading="button_orders_loading" @click="getAllSpu" round>得物所有spu信息</el-button>
        <el-button type="primary" :loading="button_orders_loading" @click="getAllSku" round>得物所有sku信息</el-button>
      </el-row>
    </div>
    <div class="funcitons">
      <span>订单明细</span>
      <span class="title"></span>
    </div>
    <div class="funcitons">
      <el-row>
        <el-button type="primary" :loading="button_orders_loading" @click="getOrderData" round>导出全部订单</el-button>
        <el-button type="primary" :loading="button_orders_loading" @click="getvalid" round>导出支付订单</el-button>
      </el-row>
    </div>
    <div class="funcitons">
      <span>销售统计</span>
      <span class="title"></span>
    </div>
    <div class="funcitons">
      <el-row>
        <!-- <el-button type="primary" :loading="button_orders_loading" @click="getsalesflow" round>导出销量统计</el-button> -->
        <el-button type="primary" :loading="button_orders_loading" @click="DailyDialogVisible = true" round class="dialog-visible">导出品牌销售数据</el-button>
        <el-dialog title="选择周期" :visible.sync="DailyDialogVisible" class="diaType">
          <el-select class="search-item" v-model="periodValue" clearable placeholder="请选择周期"><el-option v-for="item in periodOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select>
          <el-button type="primary" @click="getDataDaily(periodValue)" round>确定</el-button>
        </el-dialog>
        <el-button type="primary" :loading="button_orders_loading" @click="SalesDialogVisible = true" round class="dialog-visible">导出单品周期销售数据</el-button>
        <el-dialog title="根据周期 - 导出销量统计" :visible.sync="SalesDialogVisible" class="diaType">
          <div>所选周期汇总导出</div>
          <el-button type="primary" :loading="button_orders_loading" @click="getSalesSpu(0, 0)" round>汇总-按spu导出销量统计</el-button>
          <el-button type="primary" :loading="button_orders_loading" @click="getSalesSkc(0, 0)" round>汇总-按skc导出销量统计</el-button>
          <el-button type="primary" :loading="button_orders_loading" @click="getSalesSkuid(0, 0)" round>汇总-按sku导出销量统计</el-button>
          <div class="funcitons">所选周期按日分组导出</div>
          <el-button type="primary" :loading="button_orders_loading" @click="getSalesSpu(1, 0)" round>分日-按spu导出销量统计</el-button>
          <el-button type="primary" :loading="button_orders_loading" @click="getSalesSkc(1, 0)" round>分日-按skc导出销量统计</el-button>
          <el-button type="primary" :loading="button_orders_loading" @click="getSalesSkuid(1, 0)" round>分日-按sku导出销量统计</el-button>
        </el-dialog>
        <el-button type="primary" :loading="button_orders_loading" @click="AllSalesDialogVisible = true" round class="dialog-visible">导出单品累销数据</el-button>
        <el-dialog title="导出累计销量统计" :visible.sync="AllSalesDialogVisible" class="diaType">
          <div>汇总导出</div>
          <el-button type="primary" :loading="button_orders_loading" @click="getSalesSpu(0, 1)" round>汇总-按spu导出销量统计</el-button>
          <el-button type="primary" :loading="button_orders_loading" @click="getSalesSkc(0, 1)" round>汇总-按skc导出销量统计</el-button>
          <el-button type="primary" :loading="button_orders_loading" @click="getSalesSkuid(0, 1)" round>汇总-按sku导出销量统计</el-button>
          <div class="funcitons">按日导出-数据过多可能报错-联系均懿</div>
          <el-button type="primary" :loading="button_orders_loading" @click="getSalesSpu(1, 1)" round>分日-按spu导出销量统计</el-button>
          <el-button type="primary" :loading="button_orders_loading" @click="getSalesSkc(1, 1)" round>分日-按skc导出销量统计</el-button>
          <el-button type="primary" :loading="button_orders_loading" @click="getSalesSkuid(1, 1)" round>分日-按sku导出销量统计</el-button>
        </el-dialog>
        <!-- <el-button type="primary" :loading="button_orders_loading" @click="ProductDialogVisible = true" round class="dialog-visible">导出单品分析</el-button> -->
        <el-dialog title="单品分析" :visible.sync="ProductDialogVisible" class="diaType">
          <!-- <el-select class="search-item" v-model="periodValue" clearable placeholder="请选择周期"><el-option v-for="item in periodOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select> -->
          <span>按spu/skc筛选：</span><el-select class="search-item" v-model="typeValue" clearable placeholder="请选择类型"><el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select> <span>模糊筛选：</span><el-input placeholder="模糊搜索货号或标题" v-model="keyword" clearable style="width: 20%; margin-right: 10px"></el-input> <br />
          <span>按商品类目筛选：</span><el-select class="search-item" v-model="categoryValue" clearable placeholder="请选择类目分类方式"><el-option v-for="item in categoryOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select>
          <el-button type="primary" @click="getProductAnalyze(typeValue, categoryValue, keyword)" round>确定</el-button>
        </el-dialog>
        <el-button type="primary" :loading="button_orders_loading" @click="getRecentSales" round>sku近期销量</el-button>
      </el-row>
    </div>
    <div class="funcitons">
      <span>入仓相关</span>
      <span class="title"></span>
    </div>
    <div class="funcitons">
      <el-row>
        <el-button type="primary" :loading="button_orders_loading" @click="StockDialogVisible = true" round class="dialog-visible">导出在仓库龄和库存</el-button>
        <el-dialog title="导出在仓库龄和库存" :visible.sync="StockDialogVisible" class="diaType">
          <el-button type="primary" :loading="button_orders_loading" @click="getSpuStock" round>按spu导出在仓库龄和库存</el-button>
          <el-button type="primary" :loading="button_orders_loading" @click="getSkcStock" round>按skc导出在仓库龄和库存</el-button>
          <el-button type="primary" :loading="button_orders_loading" @click="getSkuStock" round>按sku导出在仓库龄和库存</el-button>
        </el-dialog>
        <el-button type="primary" :loading="button_orders_loading" @click="getappoint" round>导出预约单</el-button>
        <el-button type="primary" :loading="button_orders_loading" @click="getstock" round>导出退货入仓</el-button>
        <el-button type="primary" :loading="button_orders_loading" @click="getSkuStockCostOpen" round>仓储费预测</el-button>
        <el-button type="primary" :loading="button_orders_loading" @click="getSalesPrice" round>入仓出价</el-button>
      </el-row>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      button_orders_loading: false,
      shop_name: "-",
      brand_name: "-",
      brandValue: [this.$store.state.system_user_brand_id],
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "最近一周",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              const startDay = start.getFullYear() + "-" + (start.getMonth() + 1) + "-" + start.getDate() + " 00:00:00";
              const endDay = end.getFullYear() + "-" + (end.getMonth() + 1) + "-" + end.getDate() + " 23:59:59";
              start.setTime(new Date(startDay).getTime() - 3600 * 1000 * 24 * 7);
              end.setTime(new Date(endDay).getTime() - 3600 * 1000 * 24)
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              const startDay = start.getFullYear() + "-" + (start.getMonth() + 1) + "-" + start.getDate() + " 00:00:00";
              const endDay = end.getFullYear() + "-" + (end.getMonth() + 1) + "-" + end.getDate() + " 23:59:59";
              start.setTime(new Date(startDay).getTime() - 3600 * 1000 * 24 * 30);
              end.setTime(new Date(endDay).getTime() - 3600 * 1000 * 24)
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              const startDay = start.getFullYear() + "-" + (start.getMonth() + 1) + "-" + start.getDate() + " 00:00:00";
              const endDay = end.getFullYear() + "-" + (end.getMonth() + 1) + "-" + end.getDate() + " 23:59:59";
              start.setTime(new Date(startDay).getTime() - 3600 * 1000 * 24 * 90);
              end.setTime(new Date(endDay).getTime() - 3600 * 1000 * 24)
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近二十一天",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              const startDay = start.getFullYear() + "-" + (start.getMonth() + 1) + "-" + start.getDate() + " 00:00:00";
              const endDay = end.getFullYear() + "-" + (end.getMonth() + 1) + "-" + end.getDate() + " 23:59:59";
              start.setTime(new Date(startDay).getTime() - 3600 * 1000 * 24 * 21);
              end.setTime(new Date(endDay).getTime() - 3600 * 1000 * 24)
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      createTime: ["00:00:00", "23:59:59"],
      brandOptions: this.$api.getBrandList(),
      DailyDialogVisible: false,
      SalesDialogVisible: false,
      AllSalesDialogVisible: false,
      ProductDialogVisible: false,
      StockDialogVisible: false,
      button_products_loading: false,
      periodOptions: [{
        value: '0',
        label: '按天统计'
      }, {
        value: '1',
        label: '按周统计'
      }, {
        value: '2',
        label: '按月统计'
      }, {
        value: '3',
        label: '按年统计'
      }],
      typeOptions: [{
        value: 'skc',
        label: 'skc'
      }, {
        value: 'spu',
        label: 'spu'
      }],
      categoryOptions: [{
        value: 'T恤',
        label: 'T恤'
      }, {
        value: '内衣袜子',
        label: '内衣袜子'
      }, {
        value: '卫衣',
        label: '卫衣'
      }, {
        value: '夹克外套',
        label: '夹克外套'
      }, {
        value: '潮鞋',
        label: '潮鞋'
      }, {
        value: '箱包',
        label: '箱包'
      }, {
        value: '羽绒棉服',
        label: '羽绒棉服'
      }, {
        value: '裤装',
        label: '裤装'
      }, {
        value: '配件',
        label: '配件'
      }],
      periodValue: '0',
      typeValue: 'skc',
      categoryValue: '',
      keyword: '',
      conditions: {}
    };
  },
  created () {
    this.$api.get("v1/user-info", null, (r) => {
      if (r.code != 200) {
        return this.$message.error(r.message);
      }
      this.shop_name = r.data.shop_name;
      this.brand_name = r.data.brand_name;
    });
  },
  methods: {
    getOrderData () {
      if (this.createTime[0] == '00:00:00') {
        alert('请选择时间段');
      } else {
        window.location.href = process.env.VUE_APP_API_URL + 'api/export/order/excel?stime=' + this.createTime[0] + '&etime=' + this.createTime[1] + '&brand=' + this.brandValue;
      }
    },
    getappoint () {
      if (this.createTime[0] == '00:00:00') {
        alert('请选择时间段');
      } else {
        window.location.href = process.env.VUE_APP_API_URL + 'api/export/appoint/excel?stime=' + this.createTime[0] + '&etime=' + this.createTime[1] + '&brand=' + this.brandValue;
      }
    },
    getstock () {
      if (this.createTime[0] == '00:00:00') {
        alert('请选择时间段');
      } else {
        window.location.href = process.env.VUE_APP_API_URL + 'api/export/stock/excel?stime=' + this.createTime[0] + '&etime=' + this.createTime[1] + '&brand=' + this.brandValue;
      }
    },
    getvalid () {
      if (this.createTime[0] == '00:00:00') {
        alert('请选择时间段');
      } else {
        window.location.href = process.env.VUE_APP_API_URL + 'api/export/valid/excel?stime=' + this.createTime[0] + '&etime=' + this.createTime[1] + '&brand=' + this.brandValue;
      }
    },
    getsalesflow () {
      if (this.createTime[0] == '00:00:00') {
        alert('请选择时间段');
      } else {
        window.location.href = process.env.VUE_APP_API_URL + 'api/export/salesFlow/excel?stime=' + this.createTime[0] + '&etime=' + this.createTime[1] + '&brand=' + this.brandValue;
      }
    },
    getSpuStock () {
      window.location.href = process.env.VUE_APP_API_URL + 'api/export/spuStock/excel?brand=' + this.brandValue;
      this.StockDialogVisible = false;
    },
    getSkuStock () {
      window.location.href = process.env.VUE_APP_API_URL + 'api/export/skuStock/excel?brand=' + this.brandValue;
      this.StockDialogVisible = false;
    },
    getSkuStockCostOpen () {
      this.$prompt('请输入预测天数', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '请填写数字'
      }).then(({ value }) => {
        this.$message({
          type: 'success',
          message: '预测天数: ' + value,
          beforeClose: this.getSkuStockCost(value)
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    getSkuStockCost (day) {
      window.location.href = process.env.VUE_APP_API_URL + 'api/export/skuStockCost/excel?brand=' + this.brandValue + '&day=' + day;
    },
    getSalesSkuid (groupbyday = 0, all = 0) {
      if (all == 1) {
        this.createTime[0] = null;
        this.createTime[1] = null;
      }
      if (this.createTime[0] == '00:00:00') {
        alert('请选择时间段');
      } else {
        window.location.href = process.env.VUE_APP_API_URL + 'api/export/salesSkuid/excel?stime=' + this.createTime[0] + '&etime=' + this.createTime[1] + '&brand=' + this.brandValue + '&groupbyday=' + groupbyday + '&all=' + all;
      }
      this.SalesDialogVisible = false;
      this.AllSalesDialogVisible = false;
    },
    getSalesSkc (groupbyday = 0, all = 0) {
      if (all == 1) {
        this.createTime[0] = null;
        this.createTime[1] = null;
      }
      if (this.createTime[0] == '00:00:00') {
        alert('请选择时间段');
      } else {
        window.location.href = process.env.VUE_APP_API_URL + 'api/export/salesSkc/excel?stime=' + this.createTime[0] + '&etime=' + this.createTime[1] + '&brand=' + this.brandValue + '&groupbyday=' + groupbyday + '&all=' + all;
      }
      this.SalesDialogVisible = false;
      this.AllSalesDialogVisible = false;
    },
    getSalesSpu (groupbyday = 0, all = 0) {
      if (all == 1) {
        this.createTime[0] = null;
        this.createTime[1] = null;
      }
      if (this.createTime[0] == '00:00:00') {
        alert('请选择时间段');
      } else {
        window.location.href = process.env.VUE_APP_API_URL + 'api/export/salesSpu/excel?stime=' + this.createTime[0] + '&etime=' + this.createTime[1] + '&brand=' + this.brandValue + '&groupbyday=' + groupbyday + '&all=' + all;
      }
      this.SalesDialogVisible = false;
      this.AllSalesDialogVisible = false;
    },
    getSalesPrice () {
      window.location.href = process.env.VUE_APP_API_URL + 'api/export/salesPrice/excel?brand=' + this.brandValue;
    },
    getAllSpu () {
      window.location.href = process.env.VUE_APP_API_URL + 'api/export/allSpu/excel?brand=' + this.brandValue;
    },
    getAllSku () {
      window.location.href = process.env.VUE_APP_API_URL + 'api/export/allSku/excel?brand=' + this.brandValue;
    },
    getDataDaily (value) {
      if (this.createTime == null) {
        this.createTime = ["00:00:00", "23:59:59"]
      }
      window.location.href = process.env.VUE_APP_API_URL + 'api/export/dataDaily/excel?stime=' + this.createTime[0] + '&etime=' + this.createTime[1] + '&brand=' + this.brandValue + '&periodValue=' + value;
      this.DailyDialogVisible = false;
    },
    getSkcStock () {
      window.location.href = process.env.VUE_APP_API_URL + 'api/export/skcStock/excel?brand=' + this.brandValue;
      this.StockDialogVisible = false;
    },
    getProductAnalyze (type, category, keyword) {
      console.log(type, category, keyword)
      if (this.createTime[0] == '00:00:00') {
        alert('请选择时间段');
      }
      window.location.href = process.env.VUE_APP_API_URL + 'api/export/productAnalyze/excel?stime=' + this.createTime[0] + '&etime=' + this.createTime[1] + '&brand=' + this.brandValue + '&typeValue=' + type + '&categoryValue=' + category + '&keyword=' + keyword;
      this.ProductDialogVisible = false;
    },
    getRecentSales () {
      window.location.href = process.env.VUE_APP_API_URL + 'api/export/recentSales/excel?brand=' + this.brandValue;
    }
  },
};
</script>

<style scoped>
.title {
  margin-left: 20px;
}
.funcitons {
  margin-top: 15px;
}
.search-item {
  margin-top: 10px;
  margin-right: 30px;
  margin-bottom: 20px;
}
.dialog-visible {
  margin-right: 10px;
}
</style>