<template>
  <div>
    <el-table ref="table" :data="topData" style="width: 100%" :max-height="tableHeight">
      <el-table-column label="序号" type="index" width="50" align="center" fixed> </el-table-column>
      <el-table-column label="得物主图" width="200" align="center" prop="picture" fixed>
        <template slot-scope="scope">
          <!-- <img :src="scope.row.picture" alt="" width="75" height="48"> -->
          <el-popover placement="right" title="" trigger="hover">
            <img :src="scope.row.picture" style="width: 750px;height: 480px">
            <img slot="reference" :src="scope.row.picture" :alt="scope.row.picture" style="max-width: 75px;max-height: 48px">
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="凡尚货号" prop="baili_article_number" width="180" align="center"> </el-table-column>
      <el-table-column label="得物货号" prop="article_number" width="180" align="center"> </el-table-column>
      <el-table-column label="款式类型" prop="baili_is_merge" width="180" align="center"></el-table-column>
      <!-- <el-table-column label="昨天销量" prop="sales_amount" width="180" align="center"> -->
      <el-table-column v-if="this.conditions.periodValue =='0'" :label="datetype[0][0].label" prop="sales_amount" width="180" align="center"></el-table-column>
      <el-table-column v-else-if="this.conditions.periodValue =='1'" :label="datetype[0][1].label" prop="sales_amount" width="180" align="center"></el-table-column>
      <el-table-column v-else-if="this.conditions.periodValue =='2'" :label="datetype[0][2].label" prop="sales_amount" width="180" align="center"></el-table-column>
      <el-table-column v-else-if="this.conditions.periodValue =='3'" :label="datetype[0][3].label" prop="sales_amount" width="180" align="center"></el-table-column>
      <el-table-column label="入仓销量" prop="storag_order" width="180" align="center"></el-table-column>
      <el-table-column label="当前入仓库存" prop="stock_qty" width="180" align="center"><!--template slot-scope="scope">{{scope.row.stock_qty?scope.row.stock_qty:'0'}}</template--></el-table-column>
      <el-table-column label="现货销量" prop="xh_order" width="180" align="center"></el-table-column>
      <el-table-column label="预售销量" prop="ys_order" width="180" align="center"></el-table-column>
      <!-- <el-table-column label="昨日gmv" prop="gmv" width="180" align="center"><template slot-scope="scope">{{parseInt(scope.row.gmv)}}</template></el-table-column> -->
      <el-table-column  v-if="this.conditions.periodValue =='0'" :label="datetype[1][0].label" prop="gmv" width="180" align="center"><template slot-scope="scope">{{parseInt(scope.row.gmv)}}</template></el-table-column>
      <el-table-column v-else-if="this.conditions.periodValue =='1'" :label="datetype[1][1].label" prop="baili_price" width="180" align="center"></el-table-column>
      <el-table-column v-else-if="this.conditions.periodValue =='2'" :label="datetype[1][2].label" prop="baili_price_all" width="180" align="center"></el-table-column>
      <el-table-column v-else-if="this.conditions.periodValue =='3'" :label="datetype[1][3].label" prop="discount_rate" width="180" align="center"><template slot-scope="scope">{{parseInt(scope.row.discount_rate*100)}}%</template></el-table-column>
      <!-- <el-table-column label="前天销量" prop="before_amount" width="180" align="center"></el-table-column> -->
      <el-table-column v-if="this.conditions.periodValue =='0'" :label="datetype[2][0].label" prop="before_amount" width="180" align="center"></el-table-column>
      <el-table-column v-else-if="this.conditions.periodValue =='1'" :label="datetype[2][1].label" prop="before_amount" width="180" align="center"></el-table-column>
      <el-table-column v-else-if="this.conditions.periodValue =='2'" :label="datetype[2][2].label" prop="before_amount" width="180" align="center"></el-table-column>
      <el-table-column v-else-if="this.conditions.periodValue =='3'" :label="datetype[2][3].label" prop="before_amount" width="180" align="center"></el-table-column>
      <!-- <el-table-column label="前天gmv" prop="before_gmv" width="180" align="center"><template slot-scope="scope">{{parseInt(scope.row.before_gmv)}}</template></el-table-column> -->
      <el-table-column v-if="this.conditions.periodValue =='0'" :label="datetype[3][0].label" prop="before_gmv" width="180" align="center"><template slot-scope="scope">{{parseInt(scope.row.before_gmv)}}</template></el-table-column>
      <el-table-column v-else-if="this.conditions.periodValue =='1'" :label="datetype[3][1].label" prop="before_gmv" width="180" align="center"><template slot-scope="scope">{{parseInt(scope.row.before_gmv)}}</template></el-table-column>
      <el-table-column v-else-if="this.conditions.periodValue =='2'" :label="datetype[3][2].label" prop="before_gmv" width="180" align="center"><template slot-scope="scope">{{parseInt(scope.row.before_gmv)}}</template></el-table-column>
      <el-table-column v-else-if="this.conditions.periodValue =='3'" :label="datetype[3][3].label" prop="before_gmv" width="180" align="center"><template slot-scope="scope">{{parseInt(scope.row.before_gmv)}}</template></el-table-column>
      <el-table-column label="环比" prop="ring_ratio" width="180" align="center"></el-table-column>
      <el-table-column label="环比率" prop="ring_ratio_rate" width="180" align="center"><template slot-scope="scope">{{parseInt(scope.row.ring_ratio_rate*100)}}%</template></el-table-column>
      <el-table-column label="平均到手价" prop="average_price" width="180" align="center"><template slot-scope="scope">{{parseInt(scope.row.before_gmv)}}</template></el-table-column>
      <!-- <p slot="append" style="text-align: center; line-height: 100px" v-show="loading">正在加载更多</p> -->
    </el-table>
  </div>
</template>

<script>
export default {
  created () {
    this.topdata();
  },
  props:[
    'toconditions'
  ],
  data () {
    return {
      tableHeight: 3000,
      topData:[],
      datetype:[
        [{label:'昨天销量'},
        {label:'上一周销量'},
        {label:'上一个月销量'},
        {label:'上一年销量'}],

        [{label:'昨天gmv'},
        {label:'上一周gmv'},
        {label:'上一个月gmv'},
        {label:'上一年gmv'}],

        [{label:'前一天销量'},
        {label:'前一周销量'},
        {label:'前一个月销量'},
        {label:'前一年销量'}],
        
        [{label:'前一天gmv'},
        {label:'前一周gmv'},
        {label:'前一个月gmv'},
        {label:'前一年gmv'}]
      ],
      conditions: this.toconditions,
    };
  },
  methods:{
    topdata () {
      console.log(this.conditions)
      this.$api.post(
        "v1/period/top/data",
        {conditions:this.conditions},
        (res)=>{
          if (res.code != 200) {
            return this.$message.error(res.message);
          }
          this.topData = res.data;
          this.$emit('getLoading', false); // this.searchLoading = false
        }
      )
    },
  }
    
};
</script>