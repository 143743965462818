<template>
  <div>
    只做部分工作
    <div>{{ bianliang }}</div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="用户管理" name="first">用户管理</el-tab-pane>
      <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
      <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
      <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data () {
    return {
      bianliang: '',
      activeName: 'first'
    }

  },
  mounted () {
    this.$api.get(
      "xuexi",
      {},
      (r) => {
        this.bianliang = r
        console.log(r)
      }
    );
  },
  methods:{
    handleClick(tab, event) {
      console.log('11111');
      console.log(tab, event);
    }
  }
}
</script>