<template>
  <div>
    <div class="block">
      <span>模糊筛选：</span><el-input placeholder="模糊搜索货号或标题" v-model="keyword" clearable style="width:10%;margin-right:10px"></el-input>
      <span>按品牌筛选：</span><el-select class="search-item" v-model="conditions.brandValue" placeholder="请选择品牌" style="width: 10%"><el-option v-for="item in brandOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select>
      <span>按spu/skc筛选：</span><el-select class="search-item" v-model="conditions.typeValue" placeholder="请选择分类方式" style="width: 10%"><el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select>
      <span>按现/前筛选：</span><el-select class="search-item" v-model="conditions.sortValue" clearable placeholder="请选择分类方式" style="width: 10%"><el-option v-for="item in sortOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select><br>
      <span>按商品类目筛选：</span><el-select class="search-item" v-model="conditions.categoryValue" clearable placeholder="请选择类目分类方式" style="width: 10%"><el-option v-for="item in categoryOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select>
      <el-date-picker class="search-item" v-model="createTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" :default-time="createTime"> </el-date-picker>
      <!-- <el-col :span="3" class="search-item">
        <el-input placeholder="模糊搜索货号或标题" v-model="keyword" clearable></el-input>
      </el-col> -->
      <el-button type="primary" :loading="searchLoading" @click="makeSearch()">搜索</el-button>
      <el-button class="search-item" type="default"  @click="makeSearchNull()">重置</el-button>
    </div>
    <!-- <el-table ref="table" :data="tableData" style="width: 100%" :max-height="tableHeight" :default-sort="{prop:'gmv_ring_ratio'/* ,order:'descending'*/}"> -->
    <el-table ref="table" :data="tableData" style="width: 100%" :max-height="tableHeight" @sort-change="sortChange">
      <el-table-column label="序号" type="index" width="70" align="center"> </el-table-column>
      <el-table-column label="商品" width="250" align="center" prop="scope">
        <template slot-scope="scope">
          <el-row align="middle" type="flex">
            <el-col :span="14">
              <div class="demo-image__preview">
                  <!-- <el-image :src="scope.row.logo_url" > </el-image> -->
                  <el-popover placement="right" title="" trigger="hover">
                    <img :src="scope.row.logo_url" style="width: 750px;height: 480px">
                    <img slot="reference" :src="scope.row.logo_url" :alt="scope.row.logo_url" style="max-width: 75px;max-height: 48px">
                  </el-popover>
              </div>
              <div style="font-weight:bold;font-size:12px">
                {{ scope.row.article_number }}
              </div>
            </el-col>
            <el-col :span="10">
              <div>
                <div class='demo_title'>
                  {{ scope.row.title }}
                </div>
                <div style="font-weight:bold">
                  {{ scope.row.baili_category_two }}
                </div>
              </div>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column label="销量" sortable="custom" width="250" align="center"  :render-header="renderHeader" prop="after_before_sale_price">
        <template slot-scope="scope">
            <div>现: {{ scope.row.after_order_number?scope.row.after_order_number:0 }}单/前: {{scope.row.before_order_number?scope.row.before_order_number:0}}单</div>
            <div>现劵后: {{ scope.row.after_sale_price }}元</div>
            <div>前劵后: {{scope.row.before_sale_price}}元</div>
            <div>差值: {{scope.row.after_before_sale_price}}元</div>
            <div v-if="scope.row.gmv_ring_ratio >= 0">gmv环比: <span style="color:red"> {{ scope.row.gmv_ring_ratio }}%</span></div>
            <div v-if="scope.row.gmv_ring_ratio < 0">gmv环比: <span style="color:green">{{ scope.row.gmv_ring_ratio }}%</span></div>
        </template>
      </el-table-column>
      <el-table-column label="折扣率" width="250" align="center" sortable="custom" prop="difference" :filters="differenceFilters" :filter-method="differenceTag">
        <template slot-scope="scope">
            <el-row :gutter="24">
              <el-col :span="13">
                <div class="grid-content bg-purple">
                  <div>吊牌价: {{ scope.row.baili_price }}元</div>
                  <div>出价: {{ scope.row.sku_price }}元</div>
                  <div>到手价: {{ scope.row.use_coupon_price }}元</div>
                </div>
            </el-col>
              <el-col :span="11">
                <div class="grid-content bg-purple">
                    <div>折扣: {{ scope.row.discount }}%</div>
                    <div v-if="scope.row.baili_min_price_rate =='暂无控价'" >控价: {{ scope.row.baili_min_price_rate }}</div>
                    <div v-if="scope.row.baili_min_price_rate != '暂无控价'" >控价: {{ scope.row.baili_min_price_rate }}%</div>
                    <!-- <div>差异: {{ scope.row.difference }}%</div> -->
                    <div v-if="scope.row.baili_min_price_rate !='暂无控价'" >差异: {{ scope.row.difference }}%</div>
                    <div v-if="scope.row.baili_min_price_rate == '暂无控价'" >差异: 无控价</div>
                  </div>
              </el-col>
            </el-row>
        </template>
      </el-table-column>
      <el-table-column label="属性" width="250" align="center" :filters="mergeFilters" :filter-method="filterTag">
        <template slot-scope="scope">
            <div>凡尚货号: {{ scope.row.baili_article_number }}</div>
            <div>是否并款: {{ scope.row.baili_is_merge }}</div>
            <div>二级类型: {{ scope.row.baili_type_two }}</div>
            <div>百丽类型: {{ scope.row.baili_type }}</div>
        </template>
      </el-table-column>
      <el-table-column label="库存情况" width="250" align="center">
        <template slot-scope="scope">
            <!-- <div>现货库存: {{ scope.row.baili_price }}</div> -->
            <div>入仓库存: {{ scope.row.stock_qty }}</div>
            <!-- <div>入仓率: {{ scope.row.baili_type_two }}</div> -->
            <div>现入仓占比: {{ (scope.row.after_warehouse_order_rate).toFixed(0) }}%</div>
            <div>前入仓占比: {{ (scope.row.before_warehouse_order_rate).toFixed(0) }}%</div>
        </template>
      </el-table-column>
      
      <p slot="append" style="text-align: center; line-height: 100px" v-show="loading">正在加载更多</p>
    </el-table>
  </div>
</template>

<script>
// import Echart from "../components/Echart.vue"
export default {
  components: {
    // 'Echart': Echart
  },
  created () {
    this.setNow();
    this.init(1);
  },
  data () {
    return {
      tableHeight: 3000,
      brandOptions: this.$api.getBrandList(),
      typeOptions: [{
        value: 'spu',
        label: 'spu'
      }, {
        value: 'skc',
        label: 'skc'
      }],
      sortOptions:[{
          value:'current_period',
          label:'现劵后gmv'
      },{
          value:'contemporaneous',
          label:'前劵后gmv'
      }],
      categoryOptions:[{
        value:'T恤',
        label:'T恤'
      },{
        value:'内衣袜子',
        label:'内衣袜子'
      },{
        value:'卫衣',
        label:'卫衣'
      },{
        value:'夹克外套',
        label:'夹克外套'
      },{
        value:'潮鞋',
        label:'潮鞋'
      },{
        value:'箱包',
        label:'箱包'
      },{
        value:'羽绒棉服',
        label:'羽绒棉服'
      },{
        value:'裤装',
        label:'裤装'
      },{
        value:'配件',
        label:'配件'
      }],
      loading: false,
      searchLoading: false,
      tableData: [],
      keyword: null,
      conditions: {
        start_create: null,
        end_create: null,
        brandValue: this.$store.state.system_user_brand_id,
        typeValue:'spu',
        sortVaule:null,
        categoryValue:null,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        firstDayOfWeek:1,
      },
      createTime: ["00:00:00", "23:59:59"],
      mergeFilters:[
        { 
          text: '并款',
          value: '并款' 
        }, 
        { 
          text: '非并款', 
          value: '非并款' 
        }
      ],
      differenceFilters:[
        {
          text:'差异<0',
          value:'-1'
        },{
          text:'差异=0',
          value:'0'
        },{
          text:'差异>0',
          value:'1'
        }
      ],
      orderArray:[],
      currentPage:1,
    };
  },
  mounted () {
    this.dom = this.$refs.table.bodyWrapper;
    this.tableHeight = document.documentElement.clientHeight - this.dom.getBoundingClientRect().top - 20
    this.dom.addEventListener("scroll", () => {
      // 滚动距离
      let scrollTop = this.dom.scrollTop;
      // 变量windowHeight是可视区的高度
      let windowHeight = this.dom.clientHeight || this.dom.clientHeight;
      // 变量scrollHeight是滚动条的总高度
      let scrollHeight = this.dom.scrollHeight || this.dom.scrollHeight;
      if (scrollTop + windowHeight - scrollHeight > -100) {
        // 获取到的不是全部数据 当滚动到底部 继续获取新的数据
        if (this.totalPage > this.currentPage && !this.loading) {
          this.currentPage++
          this.init(this.currentPage);
          this.tableHeight = document.documentElement.clientHeight - this.dom.getBoundingClientRect().top 
        }
      }
    });
  },
  methods: {
    setNow () {
      let myDate = new Date();
      let year = myDate.getFullYear()
      let month = myDate.getMonth() < 10 ? '0' + (myDate.getMonth() + 1) : (myDate.getMonth() + 1)
      let date = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()
      let sdate = year + "-" + month + "-" + date+ " 00:00:00"
      let edate = year + "-" + month + "-" + date + " 23:59:59"
      this.createTime = [sdate, edate]
      this.conditions.start_create = sdate
      this.conditions.end_create = edate
    },
    init (page) {      
      if (!this.loading) {
        if(this.conditions.brandValue == 0){
          this.searchLoading = false;
          return this.$message.error('暂时不能选全部品牌');
        }
        this.loading = true;
        this.currentPage = page;
        this.searchLoading = true;
        this.$api.post(
          "v1/product/situation/data",
          {conditions: this.conditions, keyword: this.keyword ,order:this.orderArray,page:page},
          (r) => {
            this.loading = false;
            if (r.code != 200) {
              return this.$message.error(r.message);
            }
            if (this.currentPage > 1) {
              this.tableData = this.tableData.concat(r.data.data);
            } else {
              this.tableData = r.data.data;
            }
            this.totalPage = r.data.meta.pagination.total_pages;
            this.searchLoading = false;
            this.$emit('getLoading', false);
          }
        );
      }
    },
    makeSearchNull () {
      this.conditions = {
        start_create: null,
        end_create: null,
        brandValue: this.$store.state.system_user_brand_id,
        typeValue:'spu',
        sortVaule:null,
        categoryValue:null,
        orderArray:[]
      }
      this.setNow()
      this.tableData = []
      this.keyword = null
      this.init(1);
    },
    makeSearch () {
      if (this.createTime) {
        this.conditions.start_create = this.createTime[0];
        this.conditions.end_create = this.createTime[1];
      } else {
        this.conditions.start_create = null;
        this.conditions.end_create = null;
      }
      this.searchLoading = true;
      this.init(1);
    },
    filterTag(value, row) {
        return row.baili_is_merge === value;
      },
    differenceTag(value,row){
      if(value<0){
        return row.difference < 0
      }else if(value == 0 ){
        return row.difference == 0
      }else if(value > 0 ){
        return row.difference > 0
      }
    },
    renderHeader(h,{column}){
      const serviceContent= [
        h(
          "div",
          {
            slot: "content",
            style: "margin-bottom:5px;margin-left:5px;"
          },
          "可按差值排序"
        ),
      ];
      return h(
          'div',
          {
            style:"display:inline"
          },
            [ 
              h('span', column.label),
              h('el-tooltip',{
                props: {
                  placement: "top"
                }
              },[
                column.label =="销量"?serviceContent:serviceContent,
                h('span', {
                  class:'el-icon-info',
                  style:'margin-left:5px;'
                })
              ]
              )
            ]
      )
      ;
    },
    sortChange({column, prop, order}){
      // console.log('column',column, 'prop',prop, 'order',order);
      if (order) {
        //order为ascending、descending 时
        // 1、该字段原本就在orderArray,2、该字段原本不在orderArray
        let flagIsHave=false
        this.orderArray.forEach(element => {
          if (element.prop === prop && !this.loading) {
            element.order=order
            flagIsHave=true
          }else{
            column.order = element.order
          }
        });
        if (!flagIsHave && !this.loading) {
          this.orderArray.push({
            prop:prop,
            order:order
          })
        }
      }else if(order === null){
          this.orderArray = []
      }
      this.init(1)
    }  
  },
};
</script>

<style scope>
.search-item {
  margin-top: 10px;
  margin-right: 30px;
  margin-bottom: 20px;
}
.demo-image__preview {
  width: 60%;
  margin: auto;
}
.demo_title{
  font-size: 12px;
  line-height: 16px;
}
</style>