<template>
  <div ref="myChart" :style="{ width: '100%', height: '700px' }"></div>
</template>

<script>
export default {
  // props: ["text", "subtext", "data"],
    props:["data"],
  mounted() {
    this.drawLine();
  },
  watch: {
    data: {
      handler() {
        this.drawLine();
      }
    },
  },
  data(){
    return{
      displayNumber:false
    };
  },
  methods: {
    drawLine(){
        const colors = ['#5470C6', '#EE6666'];
        const data = [data];
        let myChart = this.$echarts.init(this.$refs.myChart);
        myChart.setOption({
          color: colors,
          tooltip: {
            trigger: 'none',
            // trigger:"axis",
            // formatter:this.data
            axisPointer: {
              type: 'cross'
            },
          },
          legend: {},
          grid: {
            top: 70,
            bottom: 50
          },
          toolbox: { 
            feature: {
              restore: {},  // 还原dataZoom
              // saveAsImage: {},   //保存图片
            }
          },
          dataZoom: [{  //x轴展现
              type: 'slider',
              xAxisIndex: [0,1],  //控制两个x轴
              show: true,
              start: 0,
              end: 100,
              handleSize: 20
            },
            {
              type: 'inside',
              start: 0,
              end: 50,
            }
          ],
          xAxis: [
            {
              type: 'category',
              axisTick: {
                alignWithLabel: true,
                interval:0
              },
              axisLine: {
                onZero: false,
                lineStyle: {
                  color: colors[0]
                }
              },
              axisPointer: {
                label: {
                  formatter: function (params) {
                    return (
                      '本期  ' +
                      params.value +
                      (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                    );
                  },
                }
              },
              axisLabel:{
                  inside:true,
                  show:true,
                  interval:0,
                  rotate:30,
                  fontSize:10,
              },
              data: this.data.current_period_day
            },
            {
              type: 'category',
              axisTick: {
                alignWithLabel: true,
                interval:0
              },
              axisLine: {
                onZero: false,
                lineStyle: {
                  color: colors[1]
                }
              },
              axisPointer: {
                label: {
                  formatter: function (params) {
                    return (
                      '同期  ' +
                      params.value +
                      (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                    );
                  }
                }
              },
              axisLabel:{
                inside:true,
                show:true,
                interval:0,
                rotate:30,
                fontSize:10,
              },
              data: this.data.contemporaneous_period_day
            }
          ],
          yAxis: [
            {
              type: 'value',
              name:'gmv',
              position: 'left',
              axisLabel: {
                formatter: '{value}',
              }
            },
            
          ],
          series: [
            {
              name: '本期',
              type: 'line',
              xAxisIndex:0,
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              label:{
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              },
              data: this.data.current_period_use_coupon_price
            },
            {
              name: '同期',
              type: 'line',
              xAxisIndex: 1,
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              data: this.data.contemporaneous_period_use_coupon_price
            }
          ]
        })
    }
  },
};
</script>