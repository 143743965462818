import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        menu_isCollapse: localStorage.getItem("menu_isCollapse") ? localStorage.getItem("menu_isCollapse") : false,
        system_user_name: localStorage.getItem("system_user_name"),
        system_user_brand_id: localStorage.getItem("system_user_brand_id"),
        system_user_shop_id: localStorage.getItem("system_user_shop_id"),
        // system_user_shop: sessionStorage.getItem("system_user_shop")
    },
    mutations: {
        setUserInfo (state, data) {
            localStorage.setItem("system_user_name", data.name)
            state.system_user_name = data.name
            localStorage.setItem("system_user_brand_id", data.brand_id.toString())
            state.system_user_brand_id = data.brand_id.toString()
            localStorage.setItem("system_user_shop_id", data.shop_id.toString())
            state.system_user_shop_id = data.shop_id.toString()
            // sessionStorage.setItem("system_user_shop", data.shop)
            // state.system_user_shop = data.shop
        },
        setMenuCollapse (state) {
            state.menu_isCollapse = String(state.menu_isCollapse) === "false" ? true : false
            localStorage.setItem("menu_isCollapse", state.menu_isCollapse)
        },
        setPhoneMenuCollapse (state) {
            state.menu_isCollapse = true
        }
    },
    actions: {}
})
