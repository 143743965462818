<template>
  <div>
    leimu
  </div>
</template>

<script>
export default {
  data () {
    return {
     
    };
  },
    leimu(){
      console.log('类目')
    },
    
};
</script>

<style scope>
.search-item {
  margin-top: 10px;
  margin-right: 30px;
  margin-bottom: 20px;
}
</style>