<template>
  <div>
    <div>
      <el-badge :value="badgeNumber['low_than_system_shop']" class="item">
        <el-button
          :type="conditions['low_than_system_shop'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('low_than_system_shop')"
          >比现货低价 - 可涨价</el-button
        >
      </el-badge>
      <el-badge :value="badgeNumber['low_than_system_fast']" class="item">
        <el-button
          :type="conditions['low_than_system_fast'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('low_than_system_fast')"
          >比入仓发货低价 - 可涨价</el-button
        >
      </el-badge>
      <el-badge
        :value="badgeNumber['more_than_system_shop']"
        class="item"
        type="success"
      >
        <el-button
          :type="conditions['more_than_system_shop'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('more_than_system_shop')"
          >比现货高价 - 可降价</el-button
        >
      </el-badge>
      <el-badge
        :value="badgeNumber['more_than_system_fast']"
        class="item"
        type="success"
      >
        <el-button
          :type="conditions['more_than_system_fast'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('more_than_system_fast')"
          >比入仓发货高价 - 可降价</el-button
        >
      </el-badge>
      <el-badge
        :value="badgeNumber['bid_qty_qe_0']"
        class="item"
        type="primary"
      >
        <el-button
          :type="conditions['bid_qty_qe_0'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('bid_qty_qe_0')"
          >出价库存为 0</el-button
        >
      </el-badge>
      <el-badge
        :value="badgeNumber['bid_qty_less_5']"
        class="item"
        type="primary"
      >
        <el-button
          :type="conditions['bid_qty_less_5'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('bid_qty_less_5')"
          >出价库存 1~5</el-button
        >
      </el-badge>
      <el-badge class="item">
        <el-button
          :type="conditions['show_hidden'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('show_hidden')"
          >隐藏 {{ badgeNumber['show_hidden'] }}件</el-button
        >
      </el-badge>
      <el-badge class="item">
        <el-button
          :type="conditions['show_attention'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('show_attention')"
          >关注 {{ badgeNumber['show_attention'] }}件</el-button
        >
      </el-badge>
      <el-badge class="item" type="warning">
        <el-button
          :type="conditions['show_all'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('show_all')"
          >显示全部 {{ badgeNumber['show_all'] }}件</el-button
        >
      </el-badge>
      <el-badge class="item-getnew">
        <el-button
          size="small"
          type="success"
          @click="getNewData"
          v-if="badgeNumber['updating_number'] == 0"
          >获取最新数据</el-button
        >
        <el-button size="small" :loading="true" type="danger" v-else
          >有
          {{ badgeNumber['updating_number'] }} 条数据正在更新，请稍后</el-button
        >
      </el-badge>
    </div>
    <el-table :data="tableData" style="width: 100%" border v-loading="loading">
      <el-table-column prop="dwgoods_spu_logo" label="图片" align="center">
        <template slot-scope="scope">
          <div class="demo-image__preview">
            <el-image
              :src="scope.row.dwgoods_spu_logo"
              :preview-src-list="[scope.row.dwgoods_spu_logo]"
            >
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="dwgoods_title" label="商品名称" align="center">
      </el-table-column>
      <el-table-column
        prop="dwgoods_article_number"
        label="货号"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="dwgoods_skus_properties"
        label="规格"
        align="center"
      >
        <template slot-scope="scope">
          <pre>{{ getProps(scope.row.dwgoods_skus_properties) }}</pre>
        </template>
      </el-table-column>
      <el-table-column prop="goods_qty_remain" label="出价库存" align="center">
      </el-table-column>
      <el-table-column prop="goods_qty_sold" label="已售数量" align="center">
      </el-table-column>
      <el-table-column
        prop="goods_normal_lowest_price"
        label="现货最低价格"
        align="center"
      >
        <template slot-scope="scope">
          ¥{{ scope.row.goods_normal_lowest_price / 100 }}
          <span
            class="ext-cal"
            :class="
              scope.row.goods_price > scope.row.goods_normal_lowest_price
                ? 'green'
                : 'red'
            "
            >{{
              comparePrice(
                scope.row.goods_price,
                scope.row.goods_normal_lowest_price
              )
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="goods_fast_lowest_price"
        label="入仓发货最低价格"
        align="center"
      >
        <template slot-scope="scope">
          ¥{{ scope.row.goods_fast_lowest_price / 100 }}
          <span
            class="ext-cal"
            :class="
              scope.row.goods_price > scope.row.goods_fast_lowest_price
                ? 'green'
                : 'red'
            "
            >{{
              comparePrice(
                scope.row.goods_price,
                scope.row.goods_fast_lowest_price
              )
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="goods_price" label="我的最低价" align="center">
        <template slot-scope="scope">
          ¥{{ scope.row.goods_price / 100 }}
        </template>
      </el-table-column>

      <el-table-column prop="updated_at" label="统计时间" align="center">
        <template slot-scope="scope">
          {{ getLastTime(scope.row.updated_at) }}
          <!-- <br />
          {{ scope.row.updated_at.substr(0, 10) }}<br />
          {{ scope.row.updated_at.substr(11, 8) }} -->
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.goods_status == 0">
            <el-button
              size="mini"
              type="default"
              @click="handleOp(scope.$index, scope.row.goods_sku_id, 3)"
              >取消隐藏</el-button
            >
          </div>
          <div v-else-if="scope.row.goods_status == 1">
            <el-button
              size="mini"
              type="default"
              @click="handleOp(scope.$index, scope.row.goods_sku_id, 3)"
              >取消关注</el-button
            >
          </div>
          <div v-else>
            <el-button
              size="mini"
              type="default"
              @click="handleOp(scope.$index, scope.row.goods_sku_id, 1)"
              >关注</el-button
            >
            <el-button
              size="mini"
              type="default"
              @click="handleOp(scope.$index, scope.row.goods_sku_id, 0)"
              >隐藏</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="shopMakePrice_total"
      :page-size="shopMakePrice_PageSize"
      :current-page="shopMakePrice_CurrentPage"
      @current-change="shopMakePriceChangePage"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  created () {
    this.init(1);
  },
  data () {
    return {
      loading: false,
      tableData: [],
      shopMakePrice_total: 0,
      shopMakePrice_PageSize: 0,
      shopMakePrice_CurrentPage: 1,
      conditions: {
        low_than_system_shop: 0,
        low_than_system_fast: 0,
        more_than_system_shop: 0,
        more_than_system_fast: 0,
        bid_qty_qe_0: 0,
        bid_qty_less_5: 0,
        show_hidden: 0,
        show_attention: 0,
        show_all: 1,
      },
      badgeNumber: {
        low_than_system_shop: null,
        low_than_system_fast: null,
        more_than_system_shop: null,
        more_than_system_fast: null,
        bid_qty_qe_0: null,
        bid_qty_less_5: null,
        show_hidden: 0,
        show_attention: 0,
        show_all: 0,
        updating_number: 0,
      },
    };
  },
  methods: {
    init (page) {
      if (!this.loading) {
        this.loading = true;
        this.$api.post(
          "get/shop/sale/list",
          { page: page, conditions: this.conditions },
          (r) => {
            this.loading = false;
            // console.log(r.data);
            if (r.errcode != 0) {
              return this.$message.error(r.errmsg);
            }
            this.tableData = r.data.items;
            this.shopMakePrice_total = r.data.total;
            this.shopMakePrice_PageSize = r.data.perPage;
            this.shopMakePrice_CurrentPage = r.data.currentPage;
            if (r.data.badgeNumber != null) {
              this.badgeNumber = r.data.badgeNumber;
            }
          }
        );
      }
    },
    shopMakePriceChangePage (v) {
      this.init(v);
    },
    comparePrice (myPrice, sysPrice) {
      let com = myPrice - sysPrice;
      if (com == 0) {
        return null;
      }
      if (com > 0) {
        return " (低 " + com / 100 + ")";
      }
      if (com < 0) {
        return " (高 " + (sysPrice - myPrice) / 100 + ")";
      }
    },
    getProps (props) {
      let json = eval("(" + props + ")");
      let str = "";
      for (var key in json) {
        str += key + ":" + json[key] + "\n";
      }
      return str;
    },
    handleOp (index, sku_id, status) {
      // console.log(stock_no + "：" + status)
      this.$api.get(
        "set/shop/sale/item/status",
        { sku_id: sku_id, status: status },
        (r) => {
          if (r.errcode != 0) {
            return this.$message.error(r.errmsg);
          }

          this.init(this.shopMakePrice_CurrentPage);
        }
      );
    },
    setCondition (key) {
      for (let key in this.conditions) {
        this.conditions[key] = 0;
      }
      this.conditions[key] = 1;
      this.init(1);
    },
    getNewData () {
      if (!this.loading) {
        this.loading = true;
        this.$api.get("update/goods/list", null, (r) => {
          this.loading = false;
          if (r.errcode != 0) {
            return this.$message.error(r.errmsg);
          }
          this.init(1);
        });
      }
    },
    getLastTime (date) {
      let begin = new Date(date)
      let now = new Date()
      let span = now.getTime() - begin.getTime()
      let result = "";
      let days = Math.floor(span / (24 * 3600 * 1000));
      if (days > 0) {
        result += days + '天前';
      } else {
        //相差小时数
        let leave1 = span % (24 * 3600 * 1000);
        let hours = Math.floor(leave1 / (3600 * 1000))
        if (hours > 0) {
          result += hours + '小时前';
        } else {
          //相差分钟
          let leave2 = leave1 % (3600 * 1000)
          let minutes = Math.floor(leave2 / (60 * 1000));
          if (minutes > 0) {
            result += minutes + '分钟前';
          } else {
            //相差秒
            let level3 = leave2 % (60 * 1000)
            let seconds = Math.round(level3 / 1000);
            result += seconds + '秒前';
          }
        }
      }
      return result
      // return d.getTime(d)
    }
  },
  computed: {},
};
</script>

  <style scope>
.ext-cal {
  font-weight: bold;
}
.green {
  color: green;
}
.red {
  color: red;
}
.el-pagination {
  margin-top: 20px;
}
.item {
  margin-top: 10px;
  margin-right: 30px;
  margin-bottom: 20px;
}
.item-getnew {
  float: right;
  margin-top: 10px;
}
</style>