<template>
  <div>
    <div class="block">
      <el-select class="search-item" v-model="conditions.brandValue" clearable placeholder="请选择品牌"><el-option v-for="item in brandOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select>
      <el-date-picker class="search-item" v-model="createTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" :default-time="createTime"> </el-date-picker>
      <el-col :span="2" class="search-item">
        <el-input placeholder="模糊搜索货号或标题" v-model="keyword" clearable></el-input>
      </el-col>
      <el-button type="primary" :loading="searchLoading" @click="makeSearch()">搜索</el-button>
      <el-button class="search-item" type="default" :loading="searchLoading" @click="makeSearchNull()">重置</el-button>
    </div>
    <el-table ref="table" :data="tableData" style="width: 100%" :max-height="tableHeight" @sort-change="sortTable">
      <el-table-column label="序号" type="index" width="50" align="center"> </el-table-column>
      <el-table-column label="商品" width="250" align="center">
        <template slot-scope="scope">
          <div class="demo-image__preview">
            <el-image :src="scope.row.spu_logo" :preview-src-list="[scope.row.spu_logo]"> </el-image>
          </div>
          <div>
            <div> 
              {{ scope.row.article_number }}
            </div>
            <div>
              {{ scope.row.title }}
            </div>
            <div>
              {{ scope.row.category_name }}
            </div>
            <div v-if="scope.row.other_numbers">
              <div>辅助货号{{ scope.row.other_numbers }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="出价库存" width="200" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.stock != null">
            <div>【入仓库存】</div>
            <div v-for="(stock,i) in scope.row.stock" :key="i+'stock'">
              <div v-if="stock.stock_qty != null">
                <div v-if="stock.not_bid_qty > 0">
                  {{stock.specs_str}}：{{stock.stock_qty}}(未出价{{ stock.not_bid_qty }})
                </div>
                <div v-else>
                  {{stock.specs_str}}：{{stock.stock_qty}}
                </div>
              </div>
              <div v-else>{{stock.props}}：已售罄</div>
            </div>            
          </div>
          <div v-else>无出价</div>
        </template>
      </el-table-column>
      <el-table-column label="客单价" width="120" align="center" sortable="custom" prop="order_avg_amount">
        <template slot-scope="scope">
          <div v-if="scope.row.order_avg_amount > 0">
            <div>均价 {{ scope.row.order_avg_amount }}元</div>
            <div>折扣率 {{ scope.row.price_percent }}%</div>
            <div>原价 {{ scope.row.auth_price }}元</div>
          </div>
          <div v-else>无</div>
        </template>
      </el-table-column>
      <el-table-column label="交易订单" width="250" align="center" sortable="custom" prop="pay_and_not_cancel_number">
        <template slot-scope="scope">
          <div v-if="scope.row.pay_and_not_cancel_number > 0">
            <echart :text="scope.row.pay_and_not_cancel_number + '单'" :subtext="scope.row.pay_and_not_cancel_order_amount + '元\r\n月' + scope.row.m_to_m + '%\r\n周' + scope.row.w_to_w + '%'" :data="scope.row.echart_pay_and_not_cancel_number"></echart>
          </div>
          <div v-else>无</div>
        </template>
      </el-table-column>
      <el-table-column label="鉴定质检通过" width="280" align="center" sortable="custom" prop="qa_pass_number">
        <template slot-scope="scope">
          <div v-if="scope.row.qa_pass_number > 0">
            <div align="center">
              <echart :text="scope.row.qa_pass_number_percent + '%'" :subtext="scope.row.qa_pass_number + '单/' + scope.row.qa_pass_sku_price + '\r\n' + scope.row.qa_pass_poundage_percent + '/' + scope.row.qa_pass_poundage" :data="scope.row.echart_qa_pass_number"></echart>
            </div>
            <!-- <div>平均费率 {{ scope.row.qa_pass_poundage_percent }} 平均服务费 {{ scope.row.qa_pass_poundage }}</div>
            <div>平均结算金额 {{ scope.row.qa_pass_sku_price }}</div> -->
            <!-- <div>{{ scope.row.qa_pass_poundage_percent }}/{{ scope.row.qa_pass_poundage }}/{{ scope.row.qa_pass_sku_price }}</div> -->
          </div>
          <div v-else>无</div>
        </template>
      </el-table-column>
      <el-table-column label="平台退货" width="280" align="center" sortable="custom" prop="platform_return_number">
        <template slot-scope="scope">
          <div v-if="scope.row.platform_return_number > 0">
            <div align="center">
              <echart :text="scope.row.platform_return_number_percent + '%'" :subtext="scope.row.platform_return_number + '单'" :data="scope.row.echart_platform_return_number"></echart>
            </div>
          </div>
          <div v-else>无</div>
        </template>
      </el-table-column>
      <el-table-column label="客户退货" width="280" align="center" sortable="custom" prop="customer_return_number">
        <template slot-scope="scope">
          <div v-if="scope.row.customer_return_number > 0">
            <div align="center">
              <echart :text="scope.row.customer_return_number_percent + '%'" :subtext="scope.row.customer_return_number + '单'" :data="scope.row.echart_customer_return_number"></echart>
            </div>
          </div>
          <div v-else>无</div>
        </template>
      </el-table-column>
      <el-table-column label="交易关闭" width="280" align="center" sortable="custom" prop="customer_cancel_number">
        <template slot-scope="scope">
          <div v-if="scope.row.customer_cancel_number > 0">
            <div align="center">
              <echart :text="scope.row.customer_cancel_number_percent + '%'" :subtext="scope.row.customer_cancel_number + '单'" :data="scope.row.echart_customer_cancel_number"></echart>
            </div>
          </div>
          <div v-else>无</div>
        </template>
      </el-table-column>
      <el-table-column label="交易完成" width="280" align="center" sortable="custom" prop="both_success_number">
        <template slot-scope="scope">
          <div v-if="scope.row.both_success_number > 0">
            <div>{{ scope.row.both_success_number_percent }}%</div>
            <div>{{ scope.row.both_success_number }}单</div>
            <div>{{ scope.row.order_amount }}元</div>
          </div>
          <div v-else>无</div>
        </template>
      </el-table-column>
      <p slot="append" style="text-align: center; line-height: 100px" v-show="loading">正在加载更多</p>
    </el-table>
  </div>
</template>

<script>
import Echart from "../components/Echart.vue"
export default {
  components: {
    'Echart': Echart
  },
  created () {
    this.setNow();
    this.init(1);
  },
  data () {
    return {
      tableHeight: 3000,
      brandOptions: [{
        value: '10227',
        label: 'CAT'
      }, {
        value: '1310',
        label: 'Champion'
      }, {
        value: '1000527',
        label: 'SLY'
      }, {
        value: '1000529',
        label: 'MOUSSY'
      }],
      loading: false,
      searchLoading: false,
      tableData: [],
      keyword: null,
      conditions: {
        start_create: null,
        end_create: null,
        brandValue: null,
      },
      currentPage: 1,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      createTime: ["00:00:00", "23:59:59"],
    };
  },
  mounted () {
    this.dom = this.$refs.table.bodyWrapper;
    this.tableHeight = document.documentElement.clientHeight - this.dom.getBoundingClientRect().top - 20
    // console.log(document.documentElement.clientHeight)
    // console.log(this.dom.offsetHeight)
    // console.log(this.dom.getBoundingClientRect().top)
    this.dom.addEventListener("scroll", () => {
      // 滚动距离
      let scrollTop = this.dom.scrollTop;
      // 变量windowHeight是可视区的高度
      let windowHeight = this.dom.clientHeight || this.dom.clientHeight;
      // 变量scrollHeight是滚动条的总高度
      let scrollHeight = this.dom.scrollHeight || this.dom.scrollHeight;
      // console.log(scrollTop + windowHeight - scrollHeight)
      if (scrollTop + windowHeight - scrollHeight > -500) {
        // 获取到的不是全部数据 当滚动到底部 继续获取新的数据
        if (this.totalPage > this.currentPage && !this.loading) {
          this.currentPage++;
          this.init(this.currentPage);
        }
      }
    });
  },
  methods: {
    setNow () {
      let myDate = new Date();
      let year = myDate.getFullYear()
      let month = myDate.getMonth() < 10 ? '0' + (myDate.getMonth() + 1) : (myDate.getMonth() + 1)
      let date = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()
      let sdate = year + "-" + month + "-01 00:00:00"
      let edate = year + "-" + month + "-" + date + " 23:59:59"
      this.createTime = [sdate, edate]
      this.conditions.start_create = sdate
      this.conditions.end_create = edate
    },
    init (page) {
      if (!this.loading) {
        this.loading = true;
        this.currentPage = page;
        this.$api.post(
          "v1/product/sale/data",
          { page: page, conditions: this.conditions, keyword: this.keyword },
          (r) => {
            this.loading = false;
            this.searchLoading = false;
            if (r.code != 200) {
              return this.$message.error(r.message);
            }
            if (this.currentPage > 1) {
              this.tableData = this.tableData.concat(r.data.data);
            } else {
              this.tableData = r.data.data;
            }
            this.totalPage = r.data.meta.pagination.total_pages;
          }
        );
      }
    },
    makeSearchNull () {
      this.conditions = {
        start_create: null,
        end_create: null,
        brandValue: null,
      }
      this.setNow()
      this.tableData = []
      this.keyword = null
      this.init(1);
    },
    makeSearch () {
      if (this.createTime) {
        this.conditions.start_create = this.createTime[0];
        this.conditions.end_create = this.createTime[1];
      } else {
        this.conditions.start_create = null;
        this.conditions.end_create = null;
      }
      this.searchLoading = true;
      this.init(1);
    },
    sortTable (column) {
      this.conditions.ordering = {
        key: column.prop,
        type: column.order
      }
      this.tableData = []
      this.init(1);
    }
  },
  computed: {},
};
</script>

<style scope>
.search-item {
  margin-top: 10px;
  margin-right: 30px;
  margin-bottom: 20px;
}
</style>