<template>
  <el-container>
    <el-main>
      <div class="login-area">
        <div class="title-text">
          <span>十克科技-得物运营数据</span>
        </div>
        <el-form :rules="rules" :model="ruleForm" ref="ruleForm">
          <el-form-item prop="accout" label="账号">
            <el-input placeholder="请输入系统账号" v-model="ruleForm.account"></el-input>
          </el-form-item>
          <el-form-item prop="pwd" label="密码">
            <el-input placeholder="请输入系统密码" show-password v-model="ruleForm.pwd"></el-input>
          </el-form-item>
          <el-form-item class="submit_button">
            <el-button type="success" icon="el-icon-check" circle @click="loginIn('ruleForm')"></el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-main>
  </el-container>
</template>

<script>
export default {
  data () {
    return {
      ruleForm: {
        pwd: "",
        account: "",
      },
      rules: {
        account: [
          {
            required: true, message: '账号不能为空', trigger: 'blur'
          }
        ],
        pwd: [
          {
            required: true, message: '密码不能为空', trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    // 把接口抽离，每个请求加token
    loginIn (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 进行登陆验证，获取token，用vuex，然后跳转
          this.$api.post('login', {
            'account': this.ruleForm.account,
            'password': this.ruleForm.pwd
          }, r => {
            console.log(r)
            if (r.code != 200) {
              return this.$message.error(r.message)
            }
            // 把name和token保存在vuex和session中
            this.$store.commit('setUserInfo', {
              'name': r.data.name,
              'brand_id': r.data.brand_id,
              'shop_id': r.data.shop_id
            })
            // this.$store.commit('setUserInfo', { 'brand_id': r.data.brand_id.toString() })
            // 把token保存在本地localStorage中
            localStorage.setItem('dewu_user_token', r.data.token)
            // this.$router.push({ name: "storage_make_price" });
            // this.$router.push({ name: "sale_daily" });
            this.$router.push({ name: "dewu_data_export" });
            // this.$router.push({ name: "dewu_data" });
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.el-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #dddddd;
  padding-bottom: 10vh;
}
.login-area {
  text-align: center;
}
.submit_button {
  margin-top: 15px;
}
.title-text {
  font-family: 'Microsoft YaHei';
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 15px;
}

.el-input input {
  width: 250px;
  text-align: center;
}

@media (max-width: 1000px) {
  .title-text {
    font-size: 18px;
  }
  .el-input input {
    width: 100%;
  }
  .submit_button {
    padding-bottom: 150px;
  }
}
</style>
