<template>
  <div>
    <div>
      <el-badge :value="badgeNumber['low_than_system_shop']" class="item">
        <el-button
          :type="conditions['low_than_system_shop'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('low_than_system_shop')"
          >比现货低价 - 可涨价</el-button
        >
      </el-badge>
      <el-badge :value="badgeNumber['low_than_system_storage']" class="item">
        <el-button
          :type="conditions['low_than_system_storage'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('low_than_system_storage')"
          >比入仓发货低价 - 可涨价</el-button
        >
      </el-badge>
      <el-badge
        :value="badgeNumber['more_than_system_shop']"
        class="item"
        type="success"
      >
        <el-button
          :type="conditions['more_than_system_shop'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('more_than_system_shop')"
          >比现货高价 - 可降价</el-button
        >
      </el-badge>
      <el-badge
        :value="badgeNumber['more_than_system_storage']"
        class="item"
        type="success"
      >
        <el-button
          :type="conditions['more_than_system_storage'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('more_than_system_storage')"
          >比入仓发货高价 - 可降价</el-button
        >
      </el-badge>
      <el-badge
        :value="badgeNumber['bid_qty_qe_0']"
        class="item"
        type="primary"
      >
        <el-button
          :type="conditions['bid_qty_qe_0'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('bid_qty_qe_0')"
          >出价库存为 0</el-button
        >
      </el-badge>
      <el-badge
        :value="badgeNumber['bid_qty_less_5']"
        class="item"
        type="primary"
      >
        <el-button
          :type="conditions['bid_qty_less_5'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('bid_qty_less_5')"
          >出价库存 1~5</el-button
        >
      </el-badge>
      <el-badge class="item">
        <el-button
          :type="conditions['show_hidden'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('show_hidden')"
          >隐藏 {{ badgeNumber['show_hidden'] }}件</el-button
        >
      </el-badge>
      <el-badge class="item">
        <el-button
          :type="conditions['show_attention'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('show_attention')"
          >关注 {{ badgeNumber['show_attention'] }}件</el-button
        >
      </el-badge>
      <el-badge class="item" type="warning">
        <el-button
          :type="conditions['show_all'] == 1 ? 'primary' : ''"
          size="small"
          @click="setCondition('show_all')"
          >显示全部 {{ badgeNumber['show_all'] }}件</el-button
        >
      </el-badge>
      <el-badge class="item-getnew">
        <el-button size="small" type="success" @click="getNewData"
          >获取最新数据</el-button
        >
      </el-badge>
    </div>
    <el-table :data="tableData" style="width: 100%" border v-loading="loading">
      <el-table-column prop="storage_logo_url" label="图片" align="center">
        <template slot-scope="scope">
          <div class="demo-image__preview">
            <el-image
              :src="scope.row.storage_logo_url"
              :preview-src-list="[scope.row.storage_logo_url]"
            >
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="storage_spu_name" label="商品名称" align="center">
      </el-table-column>
      <el-table-column
        prop="storage_article_number"
        label="货号"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="storage_props" label="规格" align="center">
        <template slot-scope="scope">
          <pre>{{ getProps(scope.row.storage_props) }}</pre>
        </template>
      </el-table-column>
      <el-table-column prop="storage_bid_qty" label="已出价库存" align="center">
      </el-table-column>
      <el-table-column
        prop="storage_not_bid_qty"
        label="未出价库存"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="storage_spot_min_price"
        label="现货最低价格"
        align="center"
      >
        <template slot-scope="scope">
          ¥{{ scope.row.storage_spot_min_price / 100 }}
          <span
            class="ext-cal"
            :class="
              scope.row.storage_my_min_price > scope.row.storage_spot_min_price
                ? 'green'
                : 'red'
            "
            >{{
              comparePrice(
                scope.row.storage_my_min_price,
                scope.row.storage_spot_min_price
              )
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="storage_current_channel_min_price"
        label="入仓发货最低价格"
        align="center"
      >
        <template slot-scope="scope">
          ¥{{ scope.row.storage_current_channel_min_price / 100 }}
          <span
            class="ext-cal"
            :class="
              scope.row.storage_my_min_price >
              scope.row.storage_current_channel_min_price
                ? 'green'
                : 'red'
            "
            >{{
              comparePrice(
                scope.row.storage_my_min_price,
                scope.row.storage_current_channel_min_price
              )
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="storage_my_min_price"
        label="我的最低价"
        align="center"
      >
        <template slot-scope="scope">
          ¥{{ scope.row.storage_my_min_price / 100 }}
        </template>
      </el-table-column>

      <el-table-column prop="created_at" label="统计时间" align="center">
        <template slot-scope="scope">
          {{ getLastTime(scope.row.created_at) }}
          <!-- {{ scope.row.created_at.substr(0, 10) }}<br />
          {{ scope.row.created_at.substr(11, 8) }} -->
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.storage_status == 0">
            <el-button
              size="mini"
              type="default"
              @click="handleOp(scope.$index, scope.row.storage_stock_no, 3)"
              >取消隐藏</el-button
            >
          </div>
          <div v-else-if="scope.row.storage_status == 1">
            <el-button
              size="mini"
              type="default"
              @click="handleOp(scope.$index, scope.row.storage_stock_no, 3)"
              >取消关注</el-button
            >
          </div>
          <div v-else>
            <el-button
              size="mini"
              type="default"
              @click="handleOp(scope.$index, scope.row.storage_stock_no, 1)"
              >关注</el-button
            >
            <el-button
              size="mini"
              type="default"
              @click="handleOp(scope.$index, scope.row.storage_stock_no, 0)"
              >隐藏</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="storageMakePrice_total"
      :page-size="storageMakePrice_PageSize"
      :current-page="storageMakePrice_CurrentPage"
      @current-change="storageMakePriceChangePage"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  created () {
    this.init(1);
  },
  data () {
    return {
      loading: false,
      tableData: [],
      storageMakePrice_total: 0,
      storageMakePrice_PageSize: 0,
      storageMakePrice_CurrentPage: 1,
      conditions: {
        'low_than_system_shop': 0,
        'low_than_system_storage': 0,
        'more_than_system_shop': 0,
        'more_than_system_storage': 0,
        'bid_qty_qe_0': 0,
        'bid_qty_less_5': 0,
        'show_hidden': 0,
        'show_attention': 0,
        'show_all': 1,
      },
      badgeNumber: {
        'low_than_system_shop': null,
        'low_than_system_storage': null,
        'more_than_system_shop': null,
        'more_than_system_storage': null,
        'bid_qty_qe_0': null,
        'bid_qty_less_5': null,
        'show_hidden': 0,
        'show_attention': 0,
        'show_all': 0,
      }
    }
  },
  methods: {
    init (page) {
      if (!this.loading) {
        this.loading = true
        this.$api.post('get/storage/sale/list', { 'page': page, 'conditions': this.conditions }, r => {
          this.loading = false
          if (r.errcode != 0) {
            return this.$message.error(r.errmsg)
          }
          this.tableData = r.data.items;
          this.storageMakePrice_total = r.data.total;
          this.storageMakePrice_PageSize = r.data.perPage;
          this.storageMakePrice_CurrentPage = r.data.currentPage;
          if (r.data.badgeNumber != null) {
            this.badgeNumber = r.data.badgeNumber
          }
        })
      }

    },
    storageMakePriceChangePage (v) {
      this.init(v)
    },
    comparePrice (myPrice, sysPrice) {
      let com = myPrice - sysPrice;
      if (com == 0) {
        return null
      }
      if (com > 0) {
        return " (低 " + com / 100 + ")";
      }
      if (com < 0) {
        return " (高 " + (sysPrice - myPrice) / 100 + ")"
      }
    },
    getProps (props) {
      let jsonData = eval(props)
      let str = "";
      jsonData.forEach(element => {
        str += element['name'] + ":" + element['value'] + "\n"
      });
      return str
    },
    handleOp (index, stock_no, status) {
      // console.log(stock_no + "：" + status)
      this.$api.get('set/storage/sale/item/status', { 'stock_no': stock_no, 'status': status }, r => {
        if (r.errcode != 0) {
          return this.$message.error(r.errmsg)
        }

        this.init(this.storageMakePrice_CurrentPage);
      })
    },
    setCondition (key) {
      for (let key in this.conditions) {
        this.conditions[key] = 0
      }
      this.conditions[key] = 1
      this.init(1);
      // this.init(this.storageMakePrice_CurrentPage == undefined ? 1 : this.storageMakePrice_CurrentPage);
    },
    getNewData () {
      if (!this.loading) {
        this.loading = true;
        this.$api.get('update/storage/sale/list', null, r => {
          this.loading = false;
          if (r.errcode != 0) {
            return this.$message.error(r.errmsg)
          }
          this.init(1);
        })
      }
    },
    getLastTime (date) {
      let begin = new Date(date)
      let now = new Date()
      let span = now.getTime() - begin.getTime()
      let result = "";
      let days = Math.floor(span / (24 * 3600 * 1000));
      if (days > 0) {
        result += days + '天前';
      } else {
        //相差小时数
        let leave1 = span % (24 * 3600 * 1000);
        let hours = Math.floor(leave1 / (3600 * 1000))
        if (hours > 0) {
          result += hours + '小时前';
        } else {
          //相差分钟
          let leave2 = leave1 % (3600 * 1000)
          let minutes = Math.floor(leave2 / (60 * 1000));
          if (minutes > 0) {
            result += minutes + '分钟前';
          } else {
            //相差秒
            let level3 = leave2 % (60 * 1000)
            let seconds = Math.round(level3 / 1000);
            result += seconds + '秒前';
          }
        }
      }
      return result
      // return d.getTime(d)
    }
  },
  computed: {}
}
  </script>

  <style scope>
.ext-cal {
  font-weight: bold;
}
.green {
  color: green;
}
.red {
  color: red;
}
.el-pagination {
  margin-top: 20px;
}
.item {
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.item-getnew {
  float: right;
  margin-top: 10px;
}
</style>