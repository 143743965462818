<template>
  <el-table :data="tableData" style="width: 100%">
    <el-table-column prop="name" label="品牌" width="180"> </el-table-column>
    <el-table-column prop="mode" label="模式" width="180"> </el-table-column>
    <el-table-column prop="access_token_expires_date" label="授权有效期" width="180"> </el-table-column>
    <el-table-column prop="refresh_token_expires_date" label="刷新令牌有效期" width="180"> </el-table-column>
    <el-table-column label="操作">
      <template slot-scope="scope">
        <el-button type='success' size="mini" @click="authorize(scope.row.id)">发起授权</el-button>
        <el-button type="primary" size="mini" @click="reAuthorize(scope.row.id)">刷新授权</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  init () {

  },
  data () {
    return {
      tableData: []
    }
  },
  mounted () {

  },
  created () {
    this.$api.get("v1/shop/list", null, (r) => {
      if (r.code != 200) {
        return this.$message.error(r.message);
      }
      this.tableData = r.data
    });
  },
  methods: {
    authorize (id) {
      window.open('https://open.dewu.com/#/authorize?response_type=code&redirect_uri=' + encodeURIComponent('http://dw.sikers.cn/api/api/authorize') + '&client_id=4486fdc3a64742a79f3de4570cae1b68&scope=all' + '&state=' + id);
    },
    reAuthorize (id) {
      this.$api.get("v1/refresh/authorize", {shopId:id}, (r) => {
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        console.log();
        return this.$message.success(r.message);
      });
    },
  },
};
</script>

<style scoped>
</style>