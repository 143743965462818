<template>
  <div>
    <div class="block">
      <el-select class="search-item" v-model="conditions.periodValue" clearable placeholder="请选择周期"><el-option v-for="item in periodOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select>
      <el-select class="search-item" v-model="conditions.brandValue" clearable placeholder="请选择品牌"><el-option v-for="item in brandOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select>
      <el-date-picker class="search-item" v-model="createTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right"> </el-date-picker>
      <el-button class="search-item" type="primary" :loading="searchLoading" @click="makeSearch(currentPage)">搜索</el-button>
    </div>
    <el-table ref="table" :data="tableData" style="width: 100%" :max-height="tableHeight">
      <el-table-column label="序号" type="index" width="50" align="center"> </el-table-column>
      <el-table-column label="日期" width="200" align="center" prop="day">
        <!-- <template slot-scope="scope"> {{ getDay(scope.row.create_time) }} </template> -->
      </el-table-column>

      <el-table-column label="营业额" prop="order_amount" width="200" align="center"> </el-table-column>
      <!-- <el-table-column label="完结销售额" prop="order_amount" width="200" align="center"> </el-table-column> -->
      <!-- <el-table-column label="完结订单量" prop="order_number" width="200" align="center"> </el-table-column>
      <el-table-column label="完结客单价" prop="order_avg_amount" width="200" align="center"> </el-table-column> -->
      <p slot="append" style="text-align: center; line-height: 100px" v-show="loading">正在加载更多</p>
    </el-table>
  </div>
</template>

<script>
export default {
  created () {
    this.init(1);
  },
  data () {
    return {
      tableHeight: 3000,
      brandOptions: [{
        value: '10227',
        label: 'CAT'
      }, {
        value: '1310',
        label: 'Champion'
      }, {
        value: '1000527',
        label: 'SLY'
      }, {
        value: '1000529',
        label: 'MOUSSY'
      }],
      periodOptions: [{
        value: '0',
        label: '按天统计'
      }, {
        value: '1',
        label: '按周统计'
      }, {
        value: '2',
        label: '按月统计'
      }, {
        value: '3',
        label: '按年统计'
      }],
      loading: false,
      searchLoading: false,
      tableData: [],
      conditions: {
        start_create: null,
        end_create: null,
        brandValue: null,
        periodValue: '按天统计',
      },
      currentPage: 1,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      createTime: "",
    };
  },
  mounted () {
    this.dom = this.$refs.table.bodyWrapper;
    this.tableHeight = document.documentElement.clientHeight - this.dom.getBoundingClientRect().top - 20
    this.dom.addEventListener("scroll", () => {
      // 滚动距离
      let scrollTop = this.dom.scrollTop;
      // 变量windowHeight是可视区的高度
      let windowHeight = this.dom.clientHeight || this.dom.clientHeight;
      // 变量scrollHeight是滚动条的总高度
      let scrollHeight = this.dom.scrollHeight || this.dom.scrollHeight;
      // console.log(scrollTop + windowHeight - scrollHeight)
      if (scrollTop + windowHeight - scrollHeight > -500) {
        // 获取到的不是全部数据 当滚动到底部 继续获取新的数据
        if (this.totalPage > this.currentPage && !this.loading) {
          this.currentPage++;
          this.init(this.currentPage);
        }
      }
    });
  },
  methods: {
    init (page) {
      if (!this.loading) {
        this.loading = true;
        this.currentPage = page;
        this.$api.post(
          "v1/period/sale/data",
          { page: page, conditions: this.conditions },
          (r) => {
            this.loading = false;
            this.searchLoading = false;
            if (r.code != 200) {
              return this.$message.error(r.message);
            }
            if (this.currentPage > 1) {
              this.tableData = this.tableData.concat(r.data.data);
            } else {
              this.tableData = r.data.data;
            }
            this.totalPage = r.data.meta.pagination.total_pages;
          }
        );
      }
    },
    makeSearch () {
      if (this.createTime) {
        this.conditions.start_create = this.createTime[0];
        this.conditions.end_create = this.createTime[1];
      }
      this.searchLoading = true;
      this.init(1);
    },
    getDay (date) {
      var week;
      date = new Date(date)
      if (date.getDay() == 0) week = "周日"
      if (date.getDay() == 1) week = "周一"
      if (date.getDay() == 2) week = "周二"
      if (date.getDay() == 3) week = "周三"
      if (date.getDay() == 4) week = "周四"
      if (date.getDay() == 5) week = "周五"
      if (date.getDay() == 6) week = "周六"
      return week;
    }
  },
  computed: {},
};
</script>

<style scope>
.search-item {
  margin-top: 10px;
  margin-right: 30px;
  margin-bottom: 20px;
}
</style>