<template>
  <div ref="myChart" :style="{ width: '250px', height: '250px' }"></div>
</template>

<script>
export default {
  props: ["text", "subtext", "data"],
  mounted() {
    this.drawLine();
  },
  watch: {
    data: {
      handler() {
        this.drawLine();
      },
    },
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(this.$refs.myChart);
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        title: {
          text: this.text,
          subtext: this.subtext,
          left: "center",
          top: "center",
          textStyle: {
            fontSize: 25,
            lineHeight: 20,
          },
          subtextStyle: {
            fontSize: 15,
          },
        },
        legend: {
          show: false,
          top: "top",
          formatter: "{name}",
        },
        series: [
          {
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "inside",
              formatter: "{b} {d}%",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "15",
                fontWeight: "bold",
              },
            },
            data: this.data,
          },
        ],
      });
    },
  },
};
</script>