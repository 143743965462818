<template>
  <div>
    <el-table ref="table" :data="tableData" style="width: 100%" :max-height="tableHeight">
      <!-- <el-table-column label="序号" type="index" width="50" align="center" fixed> </el-table-column> -->
      <el-table-column label="日期" width="200" align="center" prop="day" fixed></el-table-column>
      <el-table-column label="品牌" width="100" align="center" prop="brand_name" fixed>
        <!-- <template slot-scope="scope"> {{ getDay(scope.row.create_time) }} </template> -->
      </el-table-column>
      <el-table-column label="去退GMV" prop="not_cancel_order_number_gmv" width="110" align="center">
        <template slot-scope="scope">
          <div>{{ parseInt(scope.row.not_cancel_order_number_gmv) }}</div>
          <div class="item-remark">(占支付{{ ((parseInt(scope.row.not_cancel_order_number_gmv) / parseInt(scope.row.pay_order_number_gmv)) * 100).toFixed(0) }}%)</div>
        </template>
      </el-table-column>
      <el-table-column label="支付订单" align="center">
        <el-table-column label="支付订单" prop="pay_order_number" width="110" align="center">
          <template slot-scope="scope">
            <div>{{ parseInt(scope.row.pay_order_number) }}</div>
            <div class="item-remark">(占全部{{ ((parseInt(scope.row.pay_order_number) / parseInt(scope.row.order_number)) * 100).toFixed(0) }}%)</div>
          </template>
        </el-table-column>
        <el-table-column label="支付GMV" prop="pay_order_number_gmv" width="90" align="center">
          <template slot-scope="scope">{{ parseInt(scope.row.pay_order_number_gmv) }}</template>
        </el-table-column>
        <el-table-column label="折扣" prop="pay_order_number_discount" width="120" align="center">
          <template slot-scope="scope">
            <div>{{ (scope.row.pay_order_number_discount * 100).toFixed(2) }}%</div>
            <div class="item-remark">(比控价{{ ((scope.row.pay_order_number_discount - scope.row.pay_order_number_baili_min_price_discount) * 100).toFixed(1) }}%)</div>
          </template>
        </el-table-column>
        <el-table-column label="客单价" prop="pay_order_number_customer_price" width="70" align="center">
          <template slot-scope="scope">{{ parseInt(scope.row.pay_order_number_customer_price) }}</template>
        </el-table-column>
        <el-table-column label="非并款折扣" prop="not_merge_pay_order_number_discount" width="120" align="center">
          <template slot-scope="scope">
            <div>{{ (scope.row.not_merge_pay_order_number_discount * 100).toFixed(2) }}%</div>
            <div class="item-remark">(比控价{{ ((scope.row.not_merge_pay_order_number_discount - scope.row.not_merge_pay_order_number_baili_min_price_discount) * 100).toFixed(1) }}%)</div>
          </template>
        </el-table-column>
        <el-table-column label="并款折扣" prop="merge_pay_order_number_discount" width="120" align="center">
          <template slot-scope="scope">
            <div>{{ (scope.row.merge_pay_order_number_discount * 100).toFixed(2) }}%</div>
            <div class="item-remark">(比控价{{ ((scope.row.merge_pay_order_number_discount - scope.row.merge_pay_order_number_baili_min_price_discount) * 100).toFixed(1) }}%)</div>
          </template>
        </el-table-column>
        <el-table-column label="非并款GMV" prop="not_merge_pay_order_number_gmv" width="100" align="center">
          <template slot-scope="scope">
            <div>{{ parseInt(scope.row.not_merge_pay_order_number_gmv) }}</div>
            <div class="item-remark">(占{{ ((parseInt(scope.row.not_merge_pay_order_number_gmv) / parseInt(scope.row.pay_order_number_gmv)) * 100).toFixed(0) }}%)</div>
          </template>
        </el-table-column>
        <el-table-column label="并款GMV" prop="merge_pay_order_number_gmv" width="90" align="center">
          <template slot-scope="scope">
            <div>{{ parseInt(scope.row.merge_pay_order_number_gmv) }}</div>
            <div class="item-remark">(占{{ ((parseInt(scope.row.merge_pay_order_number_gmv) / parseInt(scope.row.pay_order_number_gmv)) * 100).toFixed(0) }}%)</div>
          </template>
        </el-table-column>
        <el-table-column label="动销SPU" prop="pay_order_spu_number" width="80" align="center"> </el-table-column>
        <el-table-column label="动销SKC" prop="pay_order_skc_number" width="80" align="center"> </el-table-column>
        <el-table-column label="动销SKU" prop="pay_order_sku_number" width="80" align="center"> </el-table-column>

        <el-table-column label="入仓销售" prop="stock_pay_order_number" width="90" align="center">
          <template slot-scope="scope">
            <div>{{ parseInt(scope.row.stock_pay_order_number) }}</div>
            <div class="item-remark">(占{{ ((parseInt(scope.row.stock_pay_order_number) / parseInt(scope.row.pay_order_number)) * 100).toFixed(0) }}%)</div>
          </template>
        </el-table-column>
        <el-table-column label="现货销售" prop="xh_pay_order_number" width="90" align="center">
          <template slot-scope="scope">
            <div>{{ parseInt(scope.row.xh_pay_order_number) }}</div>
            <div class="item-remark">(占{{ ((parseInt(scope.row.xh_pay_order_number) / parseInt(scope.row.pay_order_number)) * 100).toFixed(0) }}%)</div>
          </template>
        </el-table-column>
        <el-table-column label="直发销售" prop="brand_pay_order_number" width="90" align="center">
          <template slot-scope="scope">
            <div>{{ parseInt(scope.row.brand_pay_order_number) }}</div>
            <div class="item-remark">(占{{ ((parseInt(scope.row.brand_pay_order_number) / parseInt(scope.row.pay_order_number)) * 100).toFixed(0) }}%)</div>
          </template>
        </el-table-column>
        <el-table-column label="预售销售" prop="ys_pay_order_number" width="90" align="center">
          <template slot-scope="scope">
            <div>{{ parseInt(scope.row.ys_pay_order_number) }}</div>
            <div class="item-remark">(占{{ ((parseInt(scope.row.ys_pay_order_number) / parseInt(scope.row.pay_order_number)) * 100).toFixed(0) }}%)</div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="支付券前" prop="pay_order_number_skuprice_gmv" width="90" align="center">
        <template slot-scope="scope">{{ parseInt(scope.row.pay_order_number_skuprice_gmv) }}</template>
      </el-table-column>
      <el-table-column label="全部订单" prop="order_number" width="80" align="center"> </el-table-column>
      <el-table-column label="支付订单" prop="pay_order_number" width="110" align="center">
        <template slot-scope="scope">
          <div>{{ parseInt(scope.row.pay_order_number) }}</div>
          <div class="item-remark">(占全部{{ ((parseInt(scope.row.pay_order_number) / parseInt(scope.row.order_number)) * 100).toFixed(0) }}%)</div>
        </template>
      </el-table-column>
      <el-table-column label="去退订单" prop="not_cancel_order_number" width="110" align="center">
        <template slot-scope="scope">
          <div>{{ parseInt(scope.row.not_cancel_order_number) }}</div>
          <div class="item-remark">(占支付{{ ((parseInt(scope.row.not_cancel_order_number) / parseInt(scope.row.pay_order_number)) * 100).toFixed(0) }}%)</div>
        </template>
      </el-table-column>
      <el-table-column label="退款订单" prop="not_cancel_order_number" width="110" align="center">
        <template slot-scope="scope">
          <div>{{ parseInt(scope.row.pay_order_number) - parseInt(scope.row.not_cancel_order_number) }}</div>
          <div class="item-remark">(占支付{{ ((1 - parseInt(scope.row.not_cancel_order_number) / parseInt(scope.row.pay_order_number)) * 100).toFixed(0) }}%)</div>
        </template>
      </el-table-column>
      <el-table-column label="退款详情" align="center">
        <el-table-column label="发货前取消" prop="cancel_before_send_order_number" width="110" align="center">
          <template slot-scope="scope">
            <div>{{ parseInt(scope.row.cancel_before_send_order_number) }}</div>
            <div class="item-remark">(占支付{{ ((parseInt(scope.row.cancel_before_send_order_number) / parseInt(scope.row.pay_order_number)) * 100).toFixed(0) }}%)</div>
          </template>
        </el-table-column>
        <el-table-column label="超时未发货" prop="cancel_by_not_send_order_number" width="110" align="center">
          <template slot-scope="scope">
            <div>{{ parseInt(scope.row.cancel_by_not_send_order_number) }}</div>
            <div class="item-remark">(占支付{{ ((parseInt(scope.row.cancel_by_not_send_order_number) / parseInt(scope.row.pay_order_number)) * 100).toFixed(0) }}%)</div>
            <div class="item-remark">(占现货{{ ((parseInt(scope.row.cancel_by_not_send_order_number) / (parseInt(scope.row.xh_pay_order_number) + parseInt(scope.row.ys_pay_order_number))) * 100).toFixed(0) }}%)</div>
          </template>
        </el-table-column>
        <el-table-column label="质检不过" prop="cancel_by_bad_quality_order_number" width="110" align="center">
          <template slot-scope="scope">
            <div>{{ parseInt(scope.row.cancel_by_bad_quality_order_number) }}</div>
            <div class="item-remark">(占支付{{ ((parseInt(scope.row.cancel_by_bad_quality_order_number) / parseInt(scope.row.pay_order_number)) * 100).toFixed(0) }}%)</div>
            <div class="item-remark">(占现货{{ ((parseInt(scope.row.cancel_by_bad_quality_order_number) / (parseInt(scope.row.xh_pay_order_number) + parseInt(scope.row.ys_pay_order_number))) * 100).toFixed(0) }}%)</div>
          </template>
        </el-table-column>
        <el-table-column label="鉴定不过" prop="cancel_by_bad_identifier_order_number" width="110" align="center">
          <template slot-scope="scope">
            <div>{{ parseInt(scope.row.cancel_by_bad_identifier_order_number) }}</div>
            <div class="item-remark">(占支付{{ ((parseInt(scope.row.cancel_by_bad_identifier_order_number) / parseInt(scope.row.pay_order_number)) * 100).toFixed(0) }}%)</div>
            <div class="item-remark">(占现货{{ ((parseInt(scope.row.cancel_by_bad_identifier_order_number) / (parseInt(scope.row.xh_pay_order_number) + parseInt(scope.row.ys_pay_order_number))) * 100).toFixed(0) }}%)</div>
          </template>
        </el-table-column>
        <el-table-column label="收货前取消" prop="cancel_before_arrive_order_number" width="110" align="center">
          <template slot-scope="scope">
            <div>{{ parseInt(scope.row.cancel_before_arrive_order_number) }}</div>
            <div class="item-remark">(占支付{{ ((parseInt(scope.row.cancel_before_arrive_order_number) / parseInt(scope.row.pay_order_number)) * 100).toFixed(0) }}%)</div>
          </template>
        </el-table-column>
        <el-table-column label="平台关闭" prop="cancel_by_dewu_order_number" width="110" align="center">
          <template slot-scope="scope">
            <div>{{ parseInt(scope.row.cancel_by_dewu_order_number) }}</div>
            <div class="item-remark">(占支付{{ ((parseInt(scope.row.cancel_by_dewu_order_number) / parseInt(scope.row.pay_order_number)) * 100).toFixed(0) }}%)</div>
          </template>
        </el-table-column>
        <el-table-column label="客退订单" prop="cancel_by_customer_order_number" width="110" align="center">
          <template slot-scope="scope">
            <div>{{ parseInt(scope.row.cancel_by_customer_order_number) }}</div>
            <div class="item-remark">(占支付{{ ((parseInt(scope.row.cancel_by_customer_order_number) / parseInt(scope.row.pay_order_number)) * 100).toFixed(0) }}%)</div>
          </template>
        </el-table-column>
      </el-table-column>

      <p slot="append" style="text-align: center; line-height: 100px" v-show="loading">正在加载更多</p>
    </el-table>
  </div>
</template>

<script>
export default {
  created () {
    this.init(1);
  },
  props: [
    'toconditions'
  ],
  data () {
    return {
      tableHeight: 700,
      tableData: [],
      createTime: "",
      createTimeTop: "",
      conditions: this.toconditions,
      currentPage: 1,
      loading: false
    };
  },
  mounted () {
    this.dom = this.$refs.table.bodyWrapper;
    this.dom.addEventListener("scroll", () => {
      // 滚动距离
      let scrollTop = this.dom.scrollTop;
      // 变量windowHeight是可视区的高度
      let windowHeight = this.dom.clientHeight || this.dom.clientHeight;
      // 变量scrollHeight是滚动条的总高度
      let scrollHeight = this.dom.scrollHeight || this.dom.scrollHeight;
      if (scrollTop + windowHeight - scrollHeight > -100) {
        // 获取到的不是全部数据 当滚动到底部 继续获取新的数据
        if (this.totalPage > this.currentPage && !this.loading) {
          this.currentPage++
          this.init(this.currentPage);
          this.tableHeight = document.documentElement.clientHeight - this.dom.getBoundingClientRect().top
        }
      }
    });
  },
  methods: {
    init (page) {
      if (!this.loading) {
        this.loading = true;
        this.currentPage = page;
        this.$api.post(
          "v1/period/daily/data",
          { page: page, conditions: this.conditions },
          (r) => {
            this.loading = false;
            if (r.code != 200) {
              return this.$message.error(r.message);
            }
            if (this.currentPage > 1) {
              this.tableData = this.tableData.concat(r.data.data);
            } else {
              this.tableData = r.data.data;
            }
            this.totalPage = r.data.meta.pagination.total_pages;
            this.$emit('getLoading', false);
          }
        );
      }
    },
  },

};
</script>

<style scope>
.search-item {
  margin-top: 10px;
  margin-right: 30px;
  margin-bottom: 20px;
}
.item-remark {
  color: gray;
  font-size: 13px;
}
</style>

<!-- <el-table-column label="订单数" prop="order_number" width="70" align="center"> </el-table-column>
        <el-table-column label="入仓销售" prop="warehousing_sales" width="80" align="center"> </el-table-column>
        <el-table-column label="现货销售" prop="straight_sales" width="80" align="center"> </el-table-column>
        <el-table-column label="直发销售" prop="brand_sales" width="80" align="center"><template slot-scope="scope">{{scope.row.brand_sales?scope.row.brand_sales:'0'}}</template></el-table-column>
        <el-table-column label="预售销售" prop="pre_sale" width="80" align="center"><template slot-scope="scope">{{scope.row.pre_sale?scope.row.pre_sale:'0'}}</template></el-table-column>
        <el-table-column label="券后销售额" prop="sku_price" width="100" align="center"><template slot-scope="scope">{{parseInt(scope.row.sku_price)}}</template></el-table-column>
        <el-table-column label="非并款销售额" prop="new_sales" width="110" align="center"><template slot-scope="scope">{{parseInt(scope.row.new_sales)}}</template></el-table-column>
        <el-table-column label="非并款销售额占比" prop="sales_percentage" width="140" align="center"><template slot-scope="scope">{{(scope.row.sales_percentage*100).toFixed(2)}}%</template></el-table-column>
        <el-table-column label="客单价" prop="customer_price" width="70" align="center"><template slot-scope="scope">{{parseInt(scope.row.customer_price)}}</template></el-table-column>
        <el-table-column label="退货金额" prop="return_amount" width="80" align="center"><template slot-scope="scope">{{parseInt(scope.row.return_amount)}}</template></el-table-column>
        <el-table-column label="退货率" prop="return_rate" width="70" align="center"><template slot-scope="scope">{{parseInt(scope.row.return_rate*100)}}%</template></el-table-column>
        <el-table-column label="商家承担劵额" prop="merchant_price" width="110" align="center"><template slot-scope="scope">{{parseInt(scope.row.merchant_price)}}</template></el-table-column>
        <el-table-column label="去退去劵销售额" prop="go_refund_coupon_sales" width="120" align="center"><template slot-scope="scope">{{parseInt(scope.row.go_refund_coupon_sales)}}</template></el-table-column>
        <el-table-column label="去退吊牌额" prop="go_refund_tag" width="100" align="center"> </el-table-column>
        <el-table-column label="折扣" prop="discount" width="70" align="center"><template slot-scope="scope">{{(scope.row.discount*100).toFixed(2)}}%</template></el-table-column>
        <el-table-column label="控价折扣" prop="baili_min_price_rate" width="100" align="center"><template slot-scope="scope">{{(scope.row.baili_min_price_rate).toFixed(2)}}% </template></el-table-column>
        <el-table-column label="非并款折扣" prop="discount_new" width="100" align="center"><template slot-scope="scope">{{(scope.row.discount_new).toFixed(2)}}%</template></el-table-column>
        <el-table-column label="非并款控价折扣" prop="new_baili_min_rate" width="120" align="center"><template slot-scope="scope">{{(scope.row.new_baili_min_rate).toFixed(2)}}% </template> </el-table-column>
        <el-table-column label="并款折扣" prop="discount_merge" width="100" align="center"><template slot-scope="scope">{{(scope.row.discount_merge).toFixed(2)}}%</template></el-table-column>
        <el-table-column label="并款控价折扣" prop="merge_baili_min_rate" width="110" align="center"><template slot-scope="scope">{{(scope.row.merge_baili_min_rate).toFixed(2)}}% </template> </el-table-column>
        <el-table-column label="质检失败金额" prop="quality_fail_price" width="110" align="center"> </el-table-column>
        <el-table-column label="质检失败率" prop="quality_fail_rate" width="110" align="center"><template slot-scope="scope">{{(scope.row.quality_fail_rate?scope.row.quality_fail_rate:0).toFixed(2)}}% </template> </el-table-column>
        <el-table-column label="客退金额" prop="customer_return_price" width="100" align="center"> </el-table-column>
        <el-table-column label="客退率" prop="customer_return_rate" width="100" align="center"> <template slot-scope="scope">{{(scope.row.customer_return_rate?scope.row.customer_return_rate:0).toFixed(2)}}% </template></el-table-column>
        <el-table-column label="取消金额" prop="cancel_price" width="100" align="center"> </el-table-column>
        <el-table-column label="取消率" prop="cancel_rate" width="100" align="center"> <template slot-scope="scope">{{(scope.row.cancel_rate?scope.row.cancel_rate:0).toFixed(2)}}% </template></el-table-column> -->

      <!-- <el-table-column label="吊牌额" prop="baili_price" width="120" align="center"><template slot-scope="scope">{{parseInt(scope.row.baili_price)}}</template></el-table-column> -->
      <!-- <el-table-column label="发货前取消订单数" prop="cancelled_orders" width="140" align="center"> </el-table-column> -->
      <!-- <el-table-column label="鉴定不通过订单数" prop="failed_orders" width="140" align="center"><template slot-scope="scope">{{scope.row.failed_orders?scope.row.failed_orders:'0'}}</template></el-table-column> -->
      <!-- <el-table-column label="客退数" prop="customer_number" width="70" align="center"><template slot-scope="scope">{{scope.row.customer_number?scope.row.customer_number:'0'}}</template></el-table-column> -->