<template>
  <div>
    <div class="block">
      <!-- <el-select class="search-item" v-model="conditions.shopValue" clearable placeholder="请选择店铺"><el-option v-for="item in ShopOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select> -->
      <el-select class="search-item" multiple v-model="conditions.brandValue" clearable placeholder="请选择品牌" @change="brandChange"><el-option v-for="item in brandOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select>
      <el-select class="search-item" v-model="conditions.periodValue" clearable placeholder="请选择周期"><el-option v-for="item in periodOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select>
      <el-date-picker class="search-item" v-model="createTime" @change="dailyTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" v-if="activeName == 'first'" :default-time="createTime"> </el-date-picker>
      <el-date-picker class="search-item" v-model="createTimeTop" @change="topTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间" v-if="activeName == 'second'"></el-date-picker>
      <el-button class="search-item" type="primary" :loading="searchLoading" @click="makeSearch(currentPage)">搜索</el-button>
    </div>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="销售统计（每小时的01/15/30/45分自动更新数据）" name="first"></el-tab-pane>
      <!-- <el-tab-pane label="top排行" name="second"></el-tab-pane> -->
      <!-- <el-tab-pane label="类目" name="third"></el-tab-pane> -->
      <router-view ref="son" :toconditions="conditions" @getLoading="searchLoad"></router-view>
    </el-tabs>
  </div>
</template>

<script>
export default {
  created () {
    this.$router.push({ name: "sale_daily" });
  },
  mounted () {
  },
  data () {
    return {
      tableHeight: 3000,
      brandOptions: this.$api.getBrandList(true),
      ShopOptions: this.$api.getShopList(true),
      periodOptions: [{
        value: '0',
        label: '按天统计'
      }, {
        value: '1',
        label: '按周统计'
      }, {
        value: '2',
        label: '按月统计'
      }, {
        value: '3',
        label: '按年统计'
      }],
      searchLoading: false,
      datetype: [
        [{ label: '昨天销量' },
        { label: '上一周销量' },
        { label: '上一个月销量' },
        { label: '上一年销量' }],

        [{ label: '昨天gmv' },
        { label: '上一周gmv' },
        { label: '上一个月gmv' },
        { label: '上一年gmv' }],

        [{ label: '前一天销量' },
        { label: '前一周销量' },
        { label: '前一个月销量' },
        { label: '前一年销量' }],

        [{ label: '前一天gmv' },
        { label: '前一周gmv' },
        { label: '前一个月gmv' },
        { label: '前一年gmv' }]
      ],
      conditions: {
        start_create: null,
        end_create: null,
        brandValue: [this.$store.state.system_user_brand_id],
        shopValue: this.$store.state.system_user_shop_id,
        periodValue: '0',
      },
      currentPage: 1,
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "最近一周",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      createTime: ["00:00:00", "23:59:59"],
      createTimeTop: "",
      activeName: 'first',
    };

  },
  methods: {
    handleClick (tab) {
      if (tab.name == "first") {
        this.conditions.start_create = null
        this.conditions.end_create = null
        this.conditions.brandValue = '1310',
          this.conditions.periodValue = '0'
        this.$router.push({ name: "sale_daily" });
      } else if (tab.name == "second") {
        this.conditions.start_create = null
        this.conditions.brandValue = '1310',
          this.conditions.periodValue = '0'
        this.$router.push({ name: "top_daily" });
      } else if (tab.name == "third") {
        this.$router.push({ name: "category_daily" });
      }
    },
    makeSearch () {
      this.searchLoading = true;
      if (this.activeName == "first") {
        this.$refs.son.init(1)
      } else if (this.activeName == "second") {
        this.$refs.son.topdata()
      } else if (this.activeName == "third") {
        // this.leimu();
      }
    },
    searchLoad (val) {
      this.searchLoading = val
    },
    dailyTime () {
      this.conditions.start_create = this.createTime[0]
      this.conditions.end_create = this.createTime[1]
      // console.log(this.conditions)
    },
    topTime () {
      this.conditions.start_create = this.createTimeTop;
      // console.log(this.conditions)
    },
    getDay (date) {
      var week;
      date = new Date(date)
      if (date.getDay() == 0) week = "周日"
      if (date.getDay() == 1) week = "周一"
      if (date.getDay() == 2) week = "周二"
      if (date.getDay() == 3) week = "周三"
      if (date.getDay() == 4) week = "周四"
      if (date.getDay() == 5) week = "周五"
      if (date.getDay() == 6) week = "周六"
      return week;
    },
    brandChange(item){
      if(item.slice(-1)[0] == 0){
        this.conditions.brandValue = ["0"]
      }else{
        if(this.conditions.brandValue.includes('0')){
          this.conditions.brandValue.splice(this.conditions.brandValue.indexOf('0'), 1)
        }      
      }
    }
  },
  computed: {},
};
</script>

<style scope>
.search-item {
  margin-top: 10px;
  margin-right: 30px;
  margin-bottom: 20px;
}
</style>