<template>
  <div>
    <div>
      <span>当前店铺：{{ shopName }}</span>
      <span class="title">品牌：{{ brandName }} </span>
    </div>
    <div class="funcitons">
      <el-button type="text" @click="exportGoodTemplate">导入商品信息及价格模板</el-button>
      <el-upload class="upload-demo" :action="importGoodsUrl" :data="importGoodsData" :on-success="handleGoodsImportSuccess" :on-error="handleGoodsImportError">
        <el-button type="primary" :disabled="shopName == '-'">导入商品信息及价格</el-button>
      </el-upload>
    </div>
  </div>
</template>

<script>
export default {
  init () {
  },
  data () {
    return {
      shopName: "-",
      brandName: "-",
      importGoodsUrl: process.env.VUE_APP_API_URL + "api/import/goods/excel",
      importGoodsData: { shop_id: 0 },
      tableHeight: 3000,
      currentPage: 1,
      fileList: [],
      tableData: [],
      titleData: []
    }
  },
  created () {
    this.$api.get("v1/user-info", null, (r) => {
      if (r.code != 200) {
        return this.$message.error(r.message);
      }
      this.shopName = r.data.shop_name;
      this.brandName = r.data.brand_name;
      this.importGoodsData.shop_id = r.data.shop_id;
    });
  },
  mounted () {

  },
  methods: {
    exportGoodTemplate () {
      window.location.href = process.env.VUE_APP_API_URL + 'api/export/goods/template/excel';
    },
    handleGoodsImportSuccess (res) {
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      return this.$message.success(res.message);
    },
    handleGoodsImportError (err) {
      let myError = err.toString();//转字符串
      myError = myError.replace("Error: ", "")//去掉前面的
      myError = JSON.parse(myError);//转对象
      return this.$message.error(myError.message);
    }
  },
};
</script>

<style scoped>
.title {
  margin-left: 20px;
}
.funcitons {
  margin-top: 15px;
}
</style>