<template>
    <div>
        <span>模糊筛选：</span><el-input placeholder="模糊搜索货号或标题" class="search-item" v-model="keyword" clearable style="width:10%;margin-right:10px"></el-input>
        <span>x轴单位：</span><el-select class="search-item" v-model="conditions.periodValue" placeholder="请选择周期"><el-option v-for="item in periodOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select>
        <span>按品牌筛选：</span><el-select class="search-item" v-model="conditions.brandValue" placeholder="请选择品牌" style="width: 10%"><el-option v-for="item in brandOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select>
        <span>按上周/上月/上年筛选：</span><el-select class="search-item" v-model="conditions.cycleValue" clearable placeholder="请选择类目分类方式" style="width: 10%"><el-option v-for="item in cycleOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select><br>
        <span>按商品类目筛选：</span><el-select class="search-item" v-model="conditions.categoryValue" clearable placeholder="请选择类目分类方式" style="width: 10%"><el-option v-for="item in categoryOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select>
        <el-date-picker class="search-item" v-model="createTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" :default-time="createTime"> </el-date-picker>
        <el-button type="primary" :loading="searchLoading" @click="makeSearch()">搜索</el-button>
        <el-button class="search-item" type="default"  @click="makeSearchNull()">重置</el-button>
        <echart :data="tableData"></echart>
    </div>
</template>
<script>
import Echart from "../components/EchartLine.vue"
export default{
    components: {
        'Echart': Echart
    },
    created () {
        this.setNow();
        this.init();
    },
    data(){
        return {
            brandOptions: this.$api.getBrandList(),
            periodOptions: [{
                value: '0',
                label: '按天统计'
            }, {
                value: '1',
                label: '按周统计'
            }, {
                value: '2',
                label: '按月统计'
            }, {
                value: '3',
                label: '按年统计'
            }],
            pickerOptions: {
            shortcuts: [
                {
                    text: "最近一周",
                    onClick (picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit("pick", [start, end]);
                    },
                },
                {
                    text: "最近一个月",
                    onClick (picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit("pick", [start, end]);
                    },
                },
                {
                    text: "最近三个月",
                    onClick (picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit("pick", [start, end]);
                    },
                },
            ],
            },
            createTime: ["00:00:00", "23:59:59"],
            categoryOptions:[{
                value:'T恤',
                label:'T恤'
            },{
                value:'内衣袜子',
                label:'内衣袜子'
            },{
                value:'卫衣',
                label:'卫衣'
            },{
                value:'夹克外套',
                label:'夹克外套'
            },{
                value:'潮鞋',
                label:'潮鞋'
            },{
                value:'箱包',
                label:'箱包'
            },{
                value:'羽绒棉服',
                label:'羽绒棉服'
            },{
                value:'裤装',
                label:'裤装'
            },{
                value:'配件',
                label:'配件'
            }],
            cycleOptions:[{
                value:'day',
                label:'昨天',
            },{
                value:'week',
                label:'上周',
            },{
                value:'month',
                label:'上月',
            },{
                value:'year',
                label:'上年',
            }],
            keyword:null,
            searchLoading: false,
            conditions: {
                start_create: null,
                end_create: null,
                brandValue: this.$store.state.system_user_brand_id,
                categoryValue:null,
                cycleValue:null,
                periodValue: '0',
            },
            tableData:[],
        };
    },
    methods:{
        setNow () {
            let myDate = new Date();
            let year = myDate.getFullYear()
            let month = myDate.getMonth() < 10 ? '0' + (myDate.getMonth() + 1) : (myDate.getMonth() + 1)
            let date = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()
            /* let sdate = year + "-" + month + "-" + date+ " 00:00:00"
            let edate = year + "-" + month + "-" + date + " 23:59:59" */
            let sdate = year + "-" + month + "-01 00:00:00"
            let edate = year + "-" + month + "-" + date + " 23:59:59"
            this.createTime = [sdate, edate]
            this.conditions.start_create = sdate
            this.conditions.end_create = edate
        },
        init () {
            if (!this.loading) {
                this.loading = true;
                this.$api.post(
                "v1/period/trend/data",
                {conditions: this.conditions, keyword: this.keyword },
                (r) => {
                    this.loading = false;
                    this.searchLoading = false;
                    if (r.code != 200) {
                    return this.$message.error(r.message);
                    }
                    this.tableData = r.data;
                }
                );
                console.log(this.tableData)
            }
        },
        makeSearch () {
            if (this.createTime) {
                this.conditions.start_create = this.createTime[0];
                this.conditions.end_create = this.createTime[1];
            } else {
                this.conditions.start_create = null;
                this.conditions.end_create = null;
            }
            this.searchLoading = true;
            this.init();
        },
        makeSearchNull () {
            this.conditions = {
                start_create: null,
                end_create: null,
                brandValue: '1310',
                categoryValue:null,
                cycleValue:null,
            }
            this.setNow()
            this.tableData = []
            this.keyword = null
            this.init();
        },
    }
}

</script>
<style scope>
.search-item {
  margin-top: 10px;
  margin-right: 30px;
  margin-bottom: 20px;
}
</style>