<template>
  <div>
    <div>
      <span>店铺：{{ shop_name }}</span
      ><span class="title">品牌：{{ brand_name }} </span>
    </div>
    <div>
      <div>
        <el-date-picker style="margin-top: 20px" class="search-item" v-model="createTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" :default-time="createTime"></el-date-picker>
        <el-select class="search-item" v-model="shopOption" clearable placeholder="请选择店铺"><el-option v-for="item in shopOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select>
        <p style="margin-top: -15px; color: gray">* 时间范围适用于“更新订单数据”,“更新退货入仓数据”</p>
      </div>
    </div>
    <div class="funcitons">
      <el-row>
        <el-button type="primary" :loading="button_all_inc_orders_loading" @click="updateTwoDwIncrementOrders" round>日常增量更新订单&退货</el-button>
        <el-button type="default" :loading="button_products_loading" @click="ordersDialogVisible = true" round>其它更新订单方式</el-button>
        <el-dialog title="订单更新方式" :visible.sync="ordersDialogVisible" class="diaType">
          <el-row>
            <el-button type="primary" :loading="button_inc_orders_loading" @click="updateDwIncrementOrders" round>增量更新订单数据</el-button>
            <el-button type="primary" :loading="button_inc_refund_orders_loading" @click="updateDwIncrementRefundOrders" round>增量更新退货订单数据</el-button>
          </el-row>
          <el-row class="funcitons">
            <el-button type="primary" :loading="button_orders_loading" @click="updateDwOrders" round>更新订单数据</el-button>
            <el-button type="primary" :loading="button_refund_orders_loading" @click="updateDwRefundOrders" round>更新退货订单数据</el-button>
          </el-row>
          <el-row class="funcitons">
            <el-button type="primary" :loading="button_all_orders_loading" @click="updateAllDwOrders" round>全部模式更新订单&退货</el-button>
          </el-row>
        </el-dialog>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-button type="primary" :loading="button_stock_bidding_loading" @click="updateStockBidding" round>获取最新入仓出价</el-button>
        <el-button type="success" :loading="button_set_stock_bidding_loading" @click="setStockBidding" round>执行批量入仓出价</el-button>
        <el-button type="primary" :loading="button_stock_loading" @click="updateStock" round>更新库存数据</el-button>
        <el-button type="primary" :loading="button_appointment_loading" @click="updateAppointment" round>更新预约单数据</el-button>
        <el-button type="primary" :loading="button_appointment_return_to_dewu_loading" @click="updateAppointmentReturnToDewu" round>更新退货入仓数据</el-button>
        <el-button type="primary" :loading="button_brands_loading" @click="getDwBrand" round>更新品牌数据</el-button>
        <el-button type="primary" :loading="button_products_loading" @click="ProductsDialogVisible = true" round>更新产品数据</el-button>
        <el-dialog title="更新产品数据方式" :visible.sync="ProductsDialogVisible" class="diaType">
          <el-button type="primary" :loading="button_products_loading" @click="updateDwProducts(1)" round>全盘更新，需等待较长时间</el-button>
          <el-button type="primary" :loading="button_products_loading" @click="updateDwProducts(4500000)" round>回滚小部分再更新</el-button>
          <el-button type="primary" :loading="button_products_loading" @click="updateDwProducts(0)" round>增量更新式</el-button>
          <!-- <el-button type="primary" :loading="button_fill_orders_loading" @click="fillOrders" round>补全订单数据</el-button> -->
        </el-dialog>
        <el-button type="primary" :loading="button_campus_loading" @click="getDwCampus" round>获取入仓园区</el-button>
        <el-button type="primary" :loading="button_stockout_loading" @click="updateStockOut" round>更新出库单数据(默认100天)</el-button>
        <el-button type="primary" :loading="button_compensate_loading" @click="updateCompensate" round>更新赔付单数据(默认100天)</el-button>
      </el-row>
    </div>
    <div class="funcitons">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item title="查看 更新订单数据任务" name="2">
          <div>
            <el-table :data="taskOrderInc" style="width: 100%">
              <!-- <el-table-column prop="shop_name" label="商家" width="150"> </el-table-column> -->
              <el-table-column prop="order_type" label="订单类型" width="100"> </el-table-column>
              <el-table-column prop="brand_name" label="品牌" width="150"> </el-table-column>
              <el-table-column prop="user_name" label="操作者" width="150"> </el-table-column>
              <el-table-column prop="start_modified" label="增量开始时间" width="180"> </el-table-column>
              <el-table-column prop="end_modified" label="增量结束时间" width="180"> </el-table-column>
              <el-table-column prop="page_no" label="任务进度" width="130"> </el-table-column>
              <el-table-column prop="status" label="增量订单任务状态" width="100"> </el-table-column>
              <el-table-column prop="remark" label="任务备注" width="130"> </el-table-column>
              <el-table-column prop="updated_at" label="最后执行时间" width="180"> </el-table-column>
            </el-table>
          </div>
          <div>
            <el-table :data="taskRefundOrderInc" style="width: 100%">
              <!-- <el-table-column prop="shop_name" label="商家" width="150"> </el-table-column> -->
              <el-table-column prop="order_type" label="订单类型" width="100"> </el-table-column>
              <el-table-column prop="brand_name" label="品牌" width="150"> </el-table-column>
              <el-table-column prop="user_name" label="操作者" width="150"> </el-table-column>
              <el-table-column prop="start_modified" label="增量开始时间" width="180"> </el-table-column>
              <el-table-column prop="end_modified" label="增量结束时间" width="180"> </el-table-column>
              <el-table-column prop="page_no" label="任务进度" width="130"> </el-table-column>
              <el-table-column prop="status" label="增量退货订单任务状态" width="100"> </el-table-column>
              <el-table-column prop="remark" label="任务备注" width="130"> </el-table-column>
              <el-table-column prop="updated_at" label="最后执行时间" width="180"> </el-table-column>
            </el-table>
          </div>
          <div>
            <el-table :data="taskOrder" style="width: 100%">
              <el-table-column prop="order_type" label="订单类型" width="180"> </el-table-column>
              <el-table-column prop="brand_name" label="品牌" width="180"> </el-table-column>
              <el-table-column prop="user_name" label="操作者" width="180"> </el-table-column>
              <el-table-column prop="page_no" label="任务进度" width="180"> </el-table-column>
              <el-table-column prop="status" label="订单任务状态" width="180"> </el-table-column>
              <el-table-column prop="remark" label="任务备注" width="180"> </el-table-column>
              <el-table-column prop="updated_at" label="最后执行时间" width="180"> </el-table-column>
            </el-table>
          </div>
          <div>
            <el-table :data="taskRefundOrder" style="width: 100%">
              <!-- <el-table-column prop="shop_name" label="商家" width="180"> </el-table-column> -->
              <el-table-column prop="order_type" label="订单类型" width="180"> </el-table-column>
              <el-table-column prop="brand_name" label="品牌" width="180"> </el-table-column>
              <el-table-column prop="user_name" label="操作者" width="180"> </el-table-column>
              <el-table-column prop="page_no" label="任务进度" width="180"> </el-table-column>
              <el-table-column prop="status" label="退货订单任务状态" width="180"> </el-table-column>
              <el-table-column prop="remark" label="任务备注" width="180"> </el-table-column>
              <el-table-column prop="updated_at" label="最后执行时间" width="180"> </el-table-column>
            </el-table>
          </div>
        </el-collapse-item>
        <el-collapse-item title="查看 更新入仓出价数据任务" name="7">
          <div>
            <el-table :data="taskStockBidding" style="width: 100%">
              <el-table-column prop="shop_name" label="商家" width="150"> </el-table-column>
              <el-table-column prop="brand_name" label="品牌" width="150"> </el-table-column>
              <el-table-column prop="user_name" label="操作者" width="150"> </el-table-column>
              <el-table-column prop="page_no" label="任务进度" width="130"> </el-table-column>
              <el-table-column prop="status" label="任务状态" width="100"> </el-table-column>
              <el-table-column prop="remark" label="任务备注" width="130"> </el-table-column>
              <el-table-column prop="updated_at" label="最后执行时间" width="180"> </el-table-column>
            </el-table>
          </div>
        </el-collapse-item>
        <el-collapse-item title="查看 设置入仓出价任务" name="8">
          <div>
            <el-table :data="taskSetStockBidding" style="width: 100%">
              <el-table-column prop="logo_url" label="主图" width="150">
                <template slot-scope="scope">
                  <img :src="scope.row.logo_url" style="width: 75px; height: 48px" />
                </template>
              </el-table-column>
              <el-table-column prop="bidding_type" label="出价渠道" width="150"> </el-table-column>
              <el-table-column prop="sku_id" label="sku_id" width="150"> </el-table-column>
              <el-table-column prop="article_number" label="得物货号" width="150"> </el-table-column>
              <el-table-column prop="properties" label="规格" width="150"> </el-table-column>
              <el-table-column prop="my_min_price" label="原出价金额" width="150"> </el-table-column>
              <el-table-column prop="bidding_price" label="新出价金额" width="150"> </el-table-column>
              <el-table-column prop="job_status" label="任务状态" width="150"> </el-table-column>
              <el-table-column prop="job_remark" label="备注" width="150"> </el-table-column>
            </el-table>
          </div>
        </el-collapse-item>
        <el-collapse-item title="查看 更新产品数据任务" name="1">
          <div>
            <el-table :data="taskProduct" style="width: 100%">
              <el-table-column prop="shop_name" label="商家" width="180"> </el-table-column>
              <el-table-column prop="brand_name" label="品牌" width="180"> </el-table-column>
              <el-table-column prop="user_name" label="操作者" width="180"> </el-table-column>
              <el-table-column prop="last_id" label="最新商品id" width="180"> </el-table-column>
              <el-table-column prop="status" label="任务状态" width="180"> </el-table-column>
              <el-table-column prop="remark" label="任务备注" width="180"> </el-table-column>
              <el-table-column prop="updated_at" label="最后执行时间" width="180"> </el-table-column>
            </el-table>
          </div>
        </el-collapse-item>

        <el-collapse-item title="查看 更新库存数据任务" name="4">
          <div>
            <el-table :data="taskStock" style="width: 100%">
              <el-table-column prop="shop_name" label="商家" width="150"> </el-table-column>
              <el-table-column prop="brand_name" label="品牌" width="150"> </el-table-column>
              <el-table-column prop="user_name" label="操作者" width="150"> </el-table-column>
              <el-table-column prop="page_no" label="任务进度" width="130"> </el-table-column>
              <el-table-column prop="status" label="任务状态" width="100"> </el-table-column>
              <el-table-column prop="remark" label="任务备注" width="130"> </el-table-column>
              <el-table-column prop="updated_at" label="最后执行时间" width="180"> </el-table-column>
            </el-table>
          </div>
        </el-collapse-item>
        <el-collapse-item title="查看 更新预约单数据任务" name="5">
          <div>
            <el-table :data="taskAppointment" style="width: 100%">
              <el-table-column prop="shop_name" label="商家" width="150"> </el-table-column>
              <el-table-column prop="brand_name" label="品牌" width="150"> </el-table-column>
              <el-table-column prop="user_name" label="操作者" width="150"> </el-table-column>
              <el-table-column prop="biz_type" label="入仓类型" width="100"> </el-table-column>
              <el-table-column prop="page_no" label="任务进度" width="130"> </el-table-column>
              <el-table-column prop="status" label="任务状态" width="100"> </el-table-column>
              <el-table-column prop="remark" label="任务备注" width="130"> </el-table-column>
              <el-table-column prop="updated_at" label="最后执行时间" width="180"> </el-table-column>
            </el-table>
          </div>
        </el-collapse-item>
        <el-collapse-item title="查看 更新退货入仓数据任务" name="6">
          <div>
            <el-table :data="taskAppointmentReturnToDewu" style="width: 100%">
              <el-table-column prop="shop_name" label="商家" width="150"> </el-table-column>
              <el-table-column prop="brand_name" label="品牌" width="150"> </el-table-column>
              <el-table-column prop="user_name" label="操作者" width="150"> </el-table-column>
              <el-table-column prop="page_no" label="任务进度" width="130"> </el-table-column>
              <el-table-column prop="start_date" label="参数开始时间" width="130"> </el-table-column>
              <el-table-column prop="end_date" label="参数结束时间" width="130"> </el-table-column>
              <el-table-column prop="status" label="任务状态" width="100"> </el-table-column>
              <el-table-column prop="remark" label="任务备注" width="130"> </el-table-column>
              <el-table-column prop="updated_at" label="最后执行时间" width="180"> </el-table-column>
            </el-table>
          </div>
        </el-collapse-item>
        <el-collapse-item title="查看 更新出库单数据任务" name="9">
          <div>
            <el-table :data="taskStockOut" style="width: 100%">
              <el-table-column prop="shop_name" label="商家" width="150"> </el-table-column>
              <el-table-column prop="brand_name" label="品牌" width="150"> </el-table-column>
              <el-table-column prop="user_name" label="操作者" width="150"> </el-table-column>
              <el-table-column prop="page_no" label="任务进度" width="130"> </el-table-column>
              <el-table-column prop="status" label="任务状态" width="100"> </el-table-column>
              <el-table-column prop="remark" label="任务备注" width="130"> </el-table-column>
              <el-table-column prop="updated_at" label="最后执行时间" width="180"> </el-table-column>
            </el-table>
          </div>
        </el-collapse-item>
        <el-collapse-item title="查看 更新赔付单数据任务" name="10">
          <div>
            <el-table :data="taskCompensate" style="width: 100%">
              <el-table-column prop="shop_name" label="商家" width="150"> </el-table-column>
              <el-table-column prop="brand_name" label="品牌" width="150"> </el-table-column>
              <el-table-column prop="user_name" label="操作者" width="150"> </el-table-column>
              <el-table-column prop="page_no" label="任务进度" width="130"> </el-table-column>
              <el-table-column prop="status" label="任务状态" width="100"> </el-table-column>
              <el-table-column prop="remark" label="任务备注" width="130"> </el-table-column>
              <el-table-column prop="updated_at" label="最后执行时间" width="180"> </el-table-column>
            </el-table>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      shopOptions: this.$api.getShopList(),
      shopOption: this.$store.state.system_user_shop_id,
      recordActiveNames: [],
      activeNames: [],
      button_goods_loading: false,
      button_brands_loading: false,
      button_products_loading: false,
      button_campus_loading: false,
      button_all_inc_orders_loading: false,
      button_all_orders_loading: false,
      button_orders_loading: false,
      button_refund_orders_loading: false,
      button_inc_orders_loading: false,
      button_inc_refund_orders_loading: false,
      button_fill_orders_loading: false,
      button_stock_loading: false,
      button_appointment_loading: false,
      button_stockout_loading: false,
      button_compensate_loading: false,
      button_stock_bidding_loading: false,
      button_set_stock_bidding_loading: false,
      button_appointment_return_to_dewu_loading: false,
      shop_name: "-",
      brand_name: "-",
      taskProduct: [],
      taskOrder: [],
      taskOrderInc: [],
      taskRefundOrder: [],
      taskRefundOrderInc: [],
      taskStock: [],
      taskStockOut: [],
      taskCompensate: [],
      taskAppointment: [],
      taskAppointmentReturnToDewu: [],
      taskStockBidding: [],
      taskSetStockBidding: [],
      ProductsDialogVisible: false,
      ordersDialogVisible: false,
      closeDialog: true,
      time_params: null,
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "最近一周",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              const startDay = start.getFullYear() + "-" + (start.getMonth() + 1) + "-" + start.getDate() + " 00:00:00";
              const endDay = end.getFullYear() + "-" + (end.getMonth() + 1) + "-" + end.getDate() + " 23:59:59";
              start.setTime(new Date(startDay).getTime() - 3600 * 1000 * 24 * 7);
              end.setTime(new Date(endDay).getTime() - 3600 * 1000 * 24)
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              const startDay = start.getFullYear() + "-" + (start.getMonth() + 1) + "-" + start.getDate() + " 00:00:00";
              const endDay = end.getFullYear() + "-" + (end.getMonth() + 1) + "-" + end.getDate() + " 23:59:59";
              start.setTime(new Date(startDay).getTime() - 3600 * 1000 * 24 * 30);
              end.setTime(new Date(endDay).getTime() - 3600 * 1000 * 24)
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              const startDay = start.getFullYear() + "-" + (start.getMonth() + 1) + "-" + start.getDate() + " 00:00:00";
              const endDay = end.getFullYear() + "-" + (end.getMonth() + 1) + "-" + end.getDate() + " 23:59:59";
              start.setTime(new Date(startDay).getTime() - 3600 * 1000 * 24 * 90);
              end.setTime(new Date(endDay).getTime() - 3600 * 1000 * 24)
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近二十一天",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              const startDay = start.getFullYear() + "-" + (start.getMonth() + 1) + "-" + start.getDate() + " 00:00:00";
              const endDay = end.getFullYear() + "-" + (end.getMonth() + 1) + "-" + end.getDate() + " 23:59:59";
              start.setTime(new Date(startDay).getTime() - 3600 * 1000 * 24 * 21);
              end.setTime(new Date(endDay).getTime() - 3600 * 1000 * 24)
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      createTime: ["00:00:00", "23:59:59"],
    };
  },
  created () {
    this.$api.get("v1/user-info", null, (r) => {
      if (r.code != 200) {
        return this.$message.error(r.message);
      }
      this.shop_name = r.data.shop_name;
      this.brand_name = r.data.brand_name;
    });
  },
  methods: {
    handleChange (val) {
      let last_id = val.slice(-1) ? val.slice(-1)[0] : null;
      if (!this.recordActiveNames.includes(last_id)) {
        switch (last_id) {
          case "1":
            this.getProductTask();
            break;
          case "2":
            this.getOrderTask();
            this.getOrderIncTask();
            this.getRefundOrderTask();
            this.getRefundOrderIncTask();
            break;
          case "4":
            this.getStockTask();
            break;
          case "5":
            this.getAppointmentTask();
            break;
          case "6":
            this.getAppointmentReturnToDewuTask();
            break;
          case "7":
            this.getStockBiddingTask();
            break;
          case "8":
            this.getSetStockBiddingTask();
            break;
          case "9":
            this.getStockOutTask();
            break;
          case "10":
            this.getCompensateTask();
            break;
        }
      }
      this.recordActiveNames = this.activeNames
    },
    getProductTask () {
      this.$api.get("v1/dewu/task/product", null, (r) => {
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        if (JSON.stringify(r.data) != '{}') {
          this.taskProduct = r.data ? r.data : null;
        }
      });
    },
    getOrderTask () {
      this.$api.get("v1/dewu/task/order", null, (r) => {
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        if (JSON.stringify(r.data) != '{}') {
          this.taskOrder = r.data ? r.data : null;
        }
      });
    },
    getOrderIncTask () {
      this.$api.get("v1/dewu/task/order/inc", null, (r) => {
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        if (JSON.stringify(r.data) != '{}') {
          this.taskOrderInc = r.data ? r.data : null;
        }
      });
    },
    getRefundOrderTask () {
      this.$api.get("v1/dewu/task/refund/order", null, (r) => {
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        if (JSON.stringify(r.data) != '{}') {
          this.taskRefundOrder = r.data ? r.data : null;
        }
      });
    },
    getRefundOrderIncTask () {
      this.$api.get("v1/dewu/task/refund/order/inc", null, (r) => {
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        if (JSON.stringify(r.data) != '{}') {
          this.taskRefundOrderInc = r.data ? r.data : null;
        }
      });
    },
    getStockTask () {
      this.$api.get("v1/dewu/task/stock", null, (r) => {
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        if (JSON.stringify(r.data) != '{}') {
          this.taskStock = r.data ? r.data : null;
        }
      });
    },
    getStockOutTask () {
      this.$api.get("v1/dewu/task/stock/out", null, (r) => {
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        if (JSON.stringify(r.data) != '{}') {
          this.taskStockOut = r.data ? r.data : null;
        }
      });
    },
    getCompensateTask () {
      this.$api.get("v1/dewu/task/compensate", null, (r) => {
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        if (JSON.stringify(r.data) != '{}') {
          this.taskCompensate = r.data ? r.data : null;
        }
      });
    },
    getAppointmentTask () {
      this.$api.get("v1/dewu/task/appointment", null, (r) => {
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        if (JSON.stringify(r.data) != '{}') {
          this.taskAppointment = r.data ? r.data : null;
        }
      });
    },
    getAppointmentReturnToDewuTask () {
      this.$api.get("v1/dewu/task/appointment/return/to/dewu", null, (r) => {
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        if (JSON.stringify(r.data) != '{}') {
          this.taskAppointmentReturnToDewu = r.data ? r.data : null;
        }
      });
    },
    getStockBiddingTask () {
      this.$api.get("v1/dewu/task/stockBidding", null, (r) => {
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        if (JSON.stringify(r.data) != '{}') {
          this.taskStockBidding = r.data ? r.data : null;
        }
      });
    },
    getSetStockBiddingTask () {
      this.$api.get("v1/dewu/task/set/stockBidding", null, (r) => {
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        if (JSON.stringify(r.data) != '{}') {
          this.taskSetStockBidding = r.data ? r.data : null;
        }
      });
    },
    updateDwIncrementOrders () {
      this.button_inc_orders_loading = true;
      if (this.createTime == null || this.createTime[0] == '00:00:00') {
        this.time_params = {
          'start_time': null,
          'end_time': null
        }
      } else {
        this.time_params = {
          'start_time': this.createTime[0],
          'end_time': this.createTime[1]
        }
      }
      this.$api.get("v1/dewu/increment/orders", {
        shopId: this.shopOption,
        start_time: this.time_params.start_time,
        end_time: this.time_params.end_time,
      }, (r) => {
        this.button_inc_orders_loading = false;
        if (r.code != 200) {
          this.button_inc_orders_loading = true;
          return this.$message.error(r.message);
        }
        return this.$message.success(r.message);
      });
    },
    updateDwIncrementRefundOrders () {
      this.button_inc_refund_orders_loading = true;
      if (this.createTime == null || this.createTime[0] == '00:00:00') {
        this.time_params = {
          'start_time': null,
          'end_time': null
        }
      } else {
        this.time_params = {
          'start_time': this.createTime[0],
          'end_time': this.createTime[1]
        }
      }
      this.$api.get("v1/dewu/refund/increment/orders", {
        shopId: this.shopOption,
        start_time: this.time_params.start_time,
        end_time: this.time_params.end_time,
      }, (r) => {
        this.button_inc_refund_orders_loading = false;
        if (r.code != 200) {
          this.button_inc_refund_orders_loading = true;
          return this.$message.error(r.message);
        }
        return this.$message.success(r.message);
      });
    },
    updateTwoDwIncrementOrders () {
      this.updateDwIncrementOrders();
      this.updateDwIncrementRefundOrders();
    },
    updateDwOrders () {
      this.button_orders_loading = true;
      if (this.createTime == null || this.createTime[0] == '00:00:00') {
        this.time_params = {
          'start_time': null,
          'end_time': null
        }
      } else {
        this.time_params = {
          'start_time': this.createTime[0],
          'end_time': this.createTime[1]
        }
      }
      this.$api.get("v1/dewu/orders", {
        shopId: this.shopOption,
        start_time: this.time_params.start_time,
        end_time: this.time_params.end_time,
      }, (r) => {
        this.button_orders_loading = false;
        if (r.code != 200) {
          this.button_orders_loading = true;
          return this.$message.error(r.message);
        }
        return this.$message.success(r.message);
      });
    },
    updateDwRefundOrders () {
      this.button_refund_orders_loading = true;
      if (this.createTime == null || this.createTime[0] == '00:00:00') {
        this.time_params = {
          'start_time': null,
          'end_time': null
        }
      } else {
        this.time_params = {
          'start_time': this.createTime[0],
          'end_time': this.createTime[1]
        }
      }
      this.$api.get("v1/dewu/refund/orders", {
        shopId: this.shopOption,
        start_time: this.time_params.start_time,
        end_time: this.time_params.end_time,
      }, (r) => {
        this.button_refund_orders_loading = false;
        if (r.code != 200) {
          this.button_refund_orders_loading = true;
          return this.$message.error(r.message);
        }
        return this.$message.success(r.message);
      });
    },
    updateAllDwOrders () {
      this.updateTwoDwIncrementOrders();
      this.updateDwOrders();
      this.updateDwRefundOrders();
    },
    updateDwProducts (type) {
      this.button_products_loading = true;
      this.$api.get("v1/dewu/products/?update_all=" + type, { shopId: this.shopOption }, (r) => {
        this.button_products_loading = false;
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        return this.$message.success(r.message);
      });
      this.ProductsDialogVisible = false;
    },
    fillOrders () {
      this.button_fill_orders_loading = true;
      this.$api.get("v1/dewu/orders/update", null, (r) => {
        this.button_fill_orders_loading = false;
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        return this.$message.success(r.message);
      });
    },
    getDwBrand () {
      this.button_brands_loading = true;
      this.$api.get("v1/dewu/brands", { shopId: this.shopOption }, (r) => {
        this.button_brands_loading = false;
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        return this.$message.success(r.message);
      });
    },
    getDwCampus () {
      this.button_campus_loading = true;
      this.$api.get("v1/dewu/campus", { shopId: this.shopOption }, (r) => {
        this.button_campus_loading = false;
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        return this.$message.success(r.message);
      });
    },
    updateStock () {
      this.button_stock_loading = true;
      this.$api.get("v1/dewu/stock", { shopId: this.shopOption }, (r) => {
        this.button_stock_loading = false;
        if (r.code != 200) {
          this.button_stock_loading = true;
          return this.$message.error(r.message);
        }
        return this.$message.success(r.message);
      });
    },
    updateAppointment () {
      this.button_appointment_loading = true;
      this.$api.get("v1/dewu/appointment", { shopId: this.shopOption }, (r) => {
        this.button_appointment_loading = false;
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        return this.$message.success(r.message);
      });
    },
    updateStockOut () {
      this.button_stockout_loading = true;
      if (this.createTime == null || this.createTime[0] == '00:00:00') {
        this.time_params = {
          'start_time': null,
          'end_time': null
        }
      } else {
        this.time_params = {
          'start_time': this.createTime[0],
          'end_time': this.createTime[1]
        }
      }
      this.$api.get("v1/dewu/stockout", {
        shopId: this.shopOption,
        start_time: this.time_params.start_time,
        end_time: this.time_params.end_time,
      }, (r) => {
        this.button_stockout_loading = false;
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        return this.$message.success(r.message);
      });
    },
    updateCompensate () {
      this.button_compensate_loading = true;
      if (this.createTime == null || this.createTime[0] == '00:00:00') {
        this.time_params = {
          'start_time': null,
          'end_time': null
        }
      } else {
        this.time_params = {
          'start_time': this.createTime[0],
          'end_time': this.createTime[1]
        }
      }
      this.$api.get("v1/dewu/appointment/compensate", {
        shopId: this.shopOption,
        start_time: this.time_params.start_time,
        end_time: this.time_params.end_time,
      }, (r) => {
        this.button_compensate_loading = false;
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        return this.$message.success(r.message);
      });
    },
    updateAppointmentReturnToDewu () {
      this.button_appointment_return_to_dewu_loading = true;
      if (this.createTime == null || this.createTime[0] == '00:00:00') {
        this.time_params = {
          'start_time': null,
          'end_time': null
        }
      } else {
        this.time_params = {
          'start_time': this.createTime[0],
          'end_time': this.createTime[1]
        }
      }
      this.$api.get("v1/dewu/return/to/stock", {
        shopId: this.shopOption,
        start_time: this.time_params.start_time,
        end_time: this.time_params.end_time,
      }, (r) => {
        this.button_appointment_return_to_dewu_loading = false;
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        return this.$message.success(r.message);
      });
    },
    updateStockBidding () {
      this.button_stock_bidding_loading = true;
      this.$api.get("v1/dewu/stock/bidding", { shopId: this.shopOption }, (r) => {
        this.button_stock_bidding_loading = false;
        if (r.code != 200) {
          this.button_stock_bidding_loading = true;
          return this.$message.error(r.message);
        }
        return this.$message.success(r.message);
      });
    },
    setStockBidding () {
      this.button_set_stock_bidding_loading = true;
      this.$api.get("v1/sale/stock/price", { shopId: this.shopOption }, (r) => {
        this.button_set_stock_bidding_loading = false;
        if (r.code != 200) {
          return this.$message.error(r.message);
        }
        return this.$message.success(r.message);
      });
    },
  },
};
</script>

<style scoped>
.title {
  margin-left: 20px;
}
.funcitons {
  margin-top: 15px;
}
.diaType + .el-button {
  margin-left: 10px;
}
</style>