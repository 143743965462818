// 配置API接口地址
// var root = ""
var root = process.env.VUE_APP_API_URL + 'api/'
// process.env.NODE_ENV == 'production' ? process.env.VUE_APP_API_URL : 'http://127.0.0.1:8000/api/'
// var root = 'http://127.0.0.1:8000/api/' 
// 引用axios
var axios = require('axios')
// 自定义判断元素类型JS
function toType (obj) {
    return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}
// 参数过滤函数
function filterNull (o) {
    for (var key in o) {
        if (o[key] === null) {
            delete o[key]
        }
        if (toType(o[key]) === 'string') {
            o[key] = o[key].trim()
        } else if (toType(o[key]) === 'object') {
            o[key] = filterNull(o[key])
        } else if (toType(o[key]) === 'array') {
            o[key] = filterNull(o[key])
        }
    }
    return o
}

function getDomian () {
    return root;
}

/*
  接口处理函数
  这个函数每个项目都是不一样的，我现在调整的是适用于
  https://cnodejs.org/api/v1 的接口，如果是其他接口
  需要根据接口的参数进行调整。参考说明文档地址：
  https://cnodejs.org/topic/5378720ed6e2d16149fa16bd
  主要是，不同的接口的成功标识和失败提示是不一致的。
  另外，不同的项目的处理方法也是不一致的，这里出错就是简单的alert
*/
function apiAxios (method, url, params, success, failure) {
    if (params) {
        params = filterNull(params)
    }
    axios({
        method: method,
        url: url,
        data: method === 'POST' || method === 'PUT' ? params : null,
        params: method === 'GET' || method === 'DELETE' ? params : null,
        baseURL: root,
        headers: {
            'token': window.localStorage['dewu_user_token']
        },
        withCredentials: false
    })
        .then(function (res) {
            if (res.status == 200) {
                if (res.data.errcode == "1") {
                    location.href = "#/login";
                    return false;
                }
                if (success) {
                    success(res.data)
                }
            } else {
                if (failure) {
                    failure(res.data)
                } else {
                    window.alert(JSON.stringify(res.data))
                }
            }
        })
        .catch(function (err) {
            let res = err.response
            if (err) {
                if (res.status == 401) {
                    location.href = "#/login";
                } else {
                    // console.log(process.env)
                    window.alert('api error, HTTP CODE: ' + res.status)
                }
            }
        })
}

// 返回在vue模板中的调用接口
export default {
    get: function (url, params, success, failure) {
        return apiAxios('GET', url, params, success, failure)
    },
    post: function (url, params, success, failure) {
        return apiAxios('POST', url, params, success, failure)
    },
    put: function (url, params, success, failure) {
        return apiAxios('PUT', url, params, success, failure)
    },
    delete: function (url, params, success, failure) {
        return apiAxios('DELETE', url, params, success, failure)
    },
    getDomian: function () {
        return getDomian()
    },
    getBrandList (hasAll = false) {
        let arr = [];
        if(hasAll){
            arr = [{'value':'0','label':'全部品牌'}];
        }
        this.get("v1/brand/list", null, (r) => {
            if (r.code != 200) {
                this.$message.error(r.message);
                return arr;
            }
            r.data.forEach(element => {
                if(element.status == 1){
                    arr.push({
                        'value': element.brand_id.toString(),
                        'label': element.brand_name,
                    });
                }                
            });
        });
        return arr;
    },
    getShopList (hasAll = false) {
        let arr = [];
        if(hasAll){
            arr = [{'value':'0','label':'全部店铺'}];
        }
        this.get("v1/view/shop/list", null, (r) => {
            if (r.code != 200) {
                this.$message.error(r.message);
                return arr;
            }
            r.data.forEach(element => {
                if(element.status == 1){
                    arr.push({
                        'value': element.id.toString(),
                        'label': element.name,
                    });
                }                
            });
        });
        return arr;
    }
}