import Login from '@/pages/Login'
import Common from '@/components/Common'
import Index from '@/pages/Index'
import StorageMakePrice from '@/pages/StorageMakePrice'
import ShopMakePrice from '@/pages/ShopMakePrice'
import IndexStock from '@/pages/IndexStock'
import DataStatistics from '@/pages/DataStatistics'
import DataStatisticsPeriod from '@/pages/DataStatisticsPeriod'
import DewuData from '@/pages/DewuData'
import DataExport from '@/pages/DataExport'
import DataImport from '@/pages/DataImport'
import HandlePriceExcel from '@/pages/HandlePriceExcel'
import Xuexi from '@/pages/Xuexi'
import DataDaily from '@/pages/Daily/DataDaily'
import SaleDaily from '@/pages/Daily/SaleDaily'
import TopDaily from '@/pages/Daily/TopDaily'
import CategoryDaily from '@/pages/Daily/CategoryDaily'
import data_product from '@/pages/DataProduct'
import trend_statistics from '@/pages/DataTrendStatistics'
import Authorize from '@/pages/Authorize'

export default [
    {
        path: '/',
        component: Common,
        meta: {
            menu_index: "1"
        },
        children: [
            {
                path: '',
                // path: '/dewu_data_export',
                name: 'dewu_data_export',
                component: DataExport,
                meta: {
                    menu_index: "5-2"
                }
            },
            {
                path: '/sale_daily',
                component: DataDaily,
                meta: {
                    menu_index: "4-4"
                },
                children:[
                    {
                    path:'',
                    name:'sale_daily',
                    component:SaleDaily,
                    }
                ]
            },
            // {
            //     path: '',
            //     component: DewuData,
            //     meta: {
            //         menu_index: "5-1"
            //     }
            // },
            {
                path: '/index',
                name: 'index',
                component: Index,
                meta: {
                    menu_index: "1"
                }
            },
            
            {
                path: '/storage_make_price',
                name: 'storage_make_price',
                component: StorageMakePrice,
                meta: {
                    menu_index: "2-1"
                }
            },
            {
                path: '/shop_make_price',
                name: 'shop_make_price',
                component: ShopMakePrice,
                meta: {
                    menu_index: "2-2"
                }
            },
            {
                path: '/handle_price_excel',
                name: 'handle_price_excel',
                component: HandlePriceExcel,
                meta: {
                    menu_index: "2-3"
                }
            },
            {
                path: '/index_stock',
                name: 'index_stock',
                component: IndexStock,
                meta: {
                    menu_index: "3"
                }
            },
            {
                path: '/data_statistics',
                name: 'data_statistics',
                component: DataStatistics,
                meta: {
                    menu_index: "4-1"
                }
            },
            {
                path: '/data_statistics_period',
                name: 'data_statistics_period',
                component: DataStatisticsPeriod,
                meta: {
                    menu_index: "4-2"
                }
            },
            {
                path: '/xuexi',
                name: 'xuexi',
                component: Xuexi,
                meta: {
                    menu_index: "4-3"
                }
            },
            {
                path: '/dewu_data',
                name: 'dewu_data',
                component: DewuData,
                meta: {
                    menu_index: "5-1"
                }
            },
            
            {
                path: '/dewu_data_import',
                name: 'dewu_data_import',
                component: DataImport,
                meta: {
                    menu_index: "5-3"
                }
            },
            {
                path: '/dewu_data_daily',
                name: 'dewu_data_daily',
                component: DataDaily,
                meta: {
                    menu_index: "4-4"
                },
                children:[
                    {
                        path:'sale_daily',
                        name:'sale_daily',
                        component:SaleDaily,
                    },
                    {
                        path:'top_daily',
                        name:'top_daily',
                        component:TopDaily,
                    },
                    {
                        path:'category_daily',
                        name:'category_daily',
                        component:CategoryDaily,
                    },
                ]
            },
            {
                path: '/data_product',
                name: 'data_product',
                component: data_product,
                meta: {
                    menu_index: "4-5"
                }
            },
            {
                path: '/trend_statistics',
                name: 'trend_statistics',
                component: trend_statistics,
                meta: {
                    menu_index: "4-6"
                }
            },
            {
                path: '/authorize',
                name: 'authorize',
                component: Authorize,
                meta: {
                    menu_index: "6"
                }
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    }
]
